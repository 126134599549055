.footer {
  background: url("../../assets/footer_back.png"),
    var(--primary-orouba, #035297);
  background-position: right;
  background-repeat: no-repeat;
  padding-top: 35px;
  padding-bottom: 10px;
}

/*END FOOTER SOCIAL DESIGN*/
.footer a {
  text-decoration: none !important;
}

.single_footer h4 {
  color: var(--heading-color) !important;
}

@media only screen and (max-width: 768px) {
  .single_footer {
    margin-bottom: 30px;
  }
}

.single_footer h4 {
  color: #fff;
  margin-top: 0;
  margin-bottom: 25px;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 20px;
  margin-bottom: 32px;
}

/* .single_footer h4::after {
  content: "";
  display: block;
  height: 2px;
  width: 40px;
  background: #fff;
  margin-top: 20px;
} */
.single_footer p {
  color: #fff;
}

.single_footer ul {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.single_footer ul li {}

.single_footer ul li a {
  color: #fff;
  -webkit-transition: all 0.3s ease 0s;
  transition: all 0.3s ease 0s;
  line-height: 36px;
  font-size: 15px;
  text-transform: capitalize;
}

.single_footer ul li a:hover {
  color: var(--heading-color);
}

.single_footer_address {}

.single_footer_address ul {}

.single_footer_address ul li {
  color: #fff;
}

.single_footer_address ul li span {
  font-weight: 400;
  color: #fff;
  line-height: 28px;
}

.contact_social ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

/*START NEWSLETTER CSS*/
.subscribe {
  display: block;
  position: relative;
  margin-top: 15px;
  width: 100%;
}

.subscribe__input {
  background-color: #fff;
  border: medium none;
  border-radius: 5px;
  color: #333;
  display: block;
  font-size: 15px;
  font-weight: 500;
  height: 60px;
  letter-spacing: 0.4px;
  margin: 0;
  padding: 0 150px 0 20px;
  text-align: center;
  text-transform: capitalize;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .subscribe__input {
    padding: 0 50px 0 20px;
  }
}

.subscribe__btn {
  background-color: transparent;
  border-radius: 0 25px 25px 0;
  color: #01c7e9;
  cursor: pointer;
  display: block;
  font-size: 20px;
  height: 60px;
  position: absolute;
  right: 0;
  top: 0;
  width: 60px;
}

.subscribe__btn i {
  transition: all 0.3s ease 0s;
}

@media only screen and (max-width: 768px) {
  .subscribe__btn {
    right: 0px;
  }
}

.subscribe__btn:hover i {
  color: var(--hover-color);
}

button {
  padding: 0;
  border: none;
  background-color: transparent;
  -webkit-border-radius: 0;
  border-radius: 0;
}

/*END NEWSLETTER CSS*/

/*START SOCIAL PROFILE CSS*/
.social_profile {
  margin-top: 40px;
}

.social_profile ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.social_profile ul li {
  float: left;
}

.social_profile ul li a {
  text-align: center;
  border: 0px;
  text-transform: uppercase;
  transition: all 0.3s ease 0s;
  margin: 0px 5px;
  font-size: 18px;
  color: #fff;
  border-radius: 30px;
  width: 50px;
  height: 50px;
  line-height: 50px;
  display: block;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

@media only screen and (max-width: 768px) {
  .social_profile ul li a {
    margin-right: 10px;
    margin-bottom: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .social_profile ul li a {
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
}

.social_profile ul li a:hover {
  background: var(--hover-color);
  border: 1px solid var(--hover-color);
  color: #fff;
  border: 0px;
}

/*END SOCIAL PROFILE CSS*/
.copyright {
  margin-top: 20px;
  /* padding-top: 40px; */
  padding: 18px 0;
  color: #fff;
  font-size: 15px;
  border-top: 2px solid rgba(255, 255, 255, 0.4);
  text-align: center;
}

.copyright a {
  color: #01c7e9;
  transition: all 0.2s ease 0s;
}

.copyright {
  margin-bottom: 0 !important;
  margin-top: 20px !important;
}

.copyright a:hover {
  color: var(--hover-color);
}

.single_footer ul li a {
  color: var(--white, #fff);
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}

.single_footer.single_footer_address .info {
  color: white !important;
}

.single_footer.single_footer_address .contact_info_row {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 24px;
}

.single_footer.single_footer_address .contact_info_row:nth-child(2) {
  justify-content: flex-start !important;
}

.single_footer.single_footer_address .contact_info_row .icon svg path,
.single_footer.single_footer_address .contact_info_row .icon svg {
  fill: #fff100;
  color: #fff100;
}

.me_social_icons {
  justify-content: flex-start;
  /* flex-direction: column; */
}

.single_footer_width {
  width: min(380px, 100%);
}

.footer_element {
  min-width: 100px !important;
  max-width: 300px !important;
}

.footer_elements {
  display: flex;
  flex-direction: row !important;
  flex-wrap: nowrap !important;
  justify-content: space-between !important;
  gap: 30px !important;
  /* padding: 20px !important; */
}


.footer_elements .footer_element {
  white-space: pre-wrap;
  width: fit-content !important;
}

.parent_social {
  margin-bottom: 16px;
}

.parent_social {
  display: flex;
  align-items: center;
  gap: 16px;
}

.parent_social>img {
  width: 82px;
  height: 63px;
  gap: 0px;
  object-fit: contain;
}

.me_social_icons {

  
  gap: 16px;
}

.me_social_icons img {
  width: 25px;
  height: 25px;
  /* gap: 0px; */
  /* opacity: 0px; */

}