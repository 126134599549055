.homeTxt {
  color: var(--main-color);
}

.img {
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}



.homeTxt h2 {
  font-weight: bold;
  font-size: 30px;
  line-height: 48px;
}

.homeTxt p {
  font-weight: 400;
  font-size: 17px;
  line-height: 28.8px;
}

.label {
  margin-bottom: 10px;
}

.label h3 {
  color: var(--main-color);
  font-size: 30px;
  font-weight: bold;
  line-height: 48px;
}

.label p {
  font-weight: 400;
  font-size: 17px;
  line-height: 28.8px;
  /* margin-right: auto; */
  color: var(--main-color);
}

.buildingsContainer p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28.8px;
  color: var(--main-color);
  margin-top: 8px;
}

.buildingsContainer h4 {
  color: var(--main-color);
  font-size: 18px;
  line-height: 21.6px;
  font-weight: bold;
  font-family: "Amaranth", sans-serif;
}


.imgContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 8px;
  flex-wrap: wrap;
}


.imgContainer h5 {
  /* text-align: center; */
  color: var(--main-color);
  margin: 10px 0px;
  font-size: 18px;
  line-height: 21.6px;
  font-family: "Amaranth", sans-serif;
}

.imgContainer p {
  color: var(--dark-color);
  font-size: 18px;
  /* width: 75%; */
  margin: 0px auto;
}

.imgContainer p span {
  font-weight: 700;
}

@media (max-width:792px) {
  .imgContainer {
    width: 45% !important;
    margin: auto;
  }

  .imgContainer.col {
    flex: 1 1 !important;
  }
}




@media (max-width:1200px) {
  .homeTxt p {
    line-height: 1.6;
  }
}


@media (max-width:980px) {
  .buildingsContainer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 30px !important;
  }
}

@media (max-width:780px) {
  .pageContainer {
    padding: 0px 50px !important;
  }

  .Pagerow {
    flex-direction: column;
    gap: 20px;
  }

  .label h3,
  .label p {
    text-align: center !important;
  }

  .label p {
    margin: 0 auto !important;
  }
}

.rowBoxes {
  flex-wrap: wrap;
}

@media (max-width:726px) {
  .rowBoxes {
    display: flex;
    flex-direction: column;
    gap: 40px;
  }
}

@media (max-width:600px) {
  .pageContainer {
    padding: 0px 25px !important;
  }
}

@media (max-width:470px) {
  .homeTxt h2 {
    font-size: 25px;
  }

  .homeTxt p {
    font-size: 16px;
  }
}

@media (max-width:500px) {
  .buildingRow {
    display: flex;
    flex-direction: column;
  }

  .buildingsContainer {
    gap: 30px;
  }
}

@media (max-width:400px) {
  .pageContainer {
    padding: 0px 15px !important;
  }
}