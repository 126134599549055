body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --main-width: 86%;
  --main-color: #035297;
  --hover-color: #7cb021;
  --heading-color: #FFF100;
  --sec-color: #002F59;
  --bg-hover:#7cb021;
}


/* .arVersion *{
  font-family: "Tajawal", sans-serif !important;
} */


.arVersion .cat_expo_ban .left h5{
  text-align: right !important;
}