.viewAllBtn{
display: flex;
width: 193px;
padding: 10px;
justify-content: center;
align-items: center;
border-radius: 16px;
border: 1px solid rgba(214, 169, 55, 0.76);
background: var(--primary-orouba, #035297);
font-family: cairo !important;
}

.brands_section.why_us_section .rowDiv, .brands_section.why_us_section .rowDiv p{
    justify-content: center !important;
    color: white !important;
    margin: 0 !important;
}

.arVersion .whyUs-text p ,
.arVersion .whyUs-text strong , 
.arVersion .whyUs-text span {
    text-align: center !important;
    color: white !important;
}