.careerForm {
  background-image: url(../../assets/images/ae80dc6490189e5cc4ccc946d433cc69.jpeg);
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 10px auto;
  /* text-align: left; */
}

.careerContent {
  max-width: 70%;
  /* margin-right: auto; */
  color: var(--dark-color);
  font-family: "Cairo", sans-serif;
}

.careerContent h2 {
  font-family: "Cairo", sans-serif;
  font-weight: 500;
}

.careerTitle p {
  font-family: "Amaranth", sans-serif;
  font-size: 18px;
  margin-top: 10px;
  max-width: 70%;
  /* margin-right: auto; */
}

.careerTitle p a {
  color: var(--dark-color);
  text-decoration: none;
  font-weight: 700;
}

.inputGroup {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
}

.form {
  max-width: 74%;
  /* margin-right: auto; */
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-top: 10px;
}

.form input,
.form textarea {
  text-align: left;
  padding: 10px 10px;
  border-radius: 4px;
  outline: none;
  border: none;
}

.form textarea {
  height: 150px;
  resize: none;
}

::placeholder {
  text-align: right;
}

.form input:focus,
.form textarea:focus {
  outline: none;
}

.form input,
.form textarea {
  width: 100%;
}

.coverLetter p {
  color: var(--dark-color);
  font-weight: 600;
  font-family: "Amaranth", sans-serif;
}

.chooseFile {
  background-color: #d9d9d9;
  color: var(--dark-color);
  padding: 7px 23px;
  border-radius: 8px;
  font-weight: 600;
}

.submitBtn {
  display: flex;
  background-color: var(--dark-color);
  color: white;
  padding: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

.chooseUsTitle {
  margin: 20px 0px;
  text-align: center;
}

.chooseUsTitle h2 {
  color: var(--main-color);
  font-size: 30px;
  line-height: 48px;
  font-weight: bold;
}

.chooseUsTitle p {
  font-size: 18px;
  color: var(--dark-color);
  line-height: 34.5px;
  margin: 0px auto;
}

.box {
  display: flex;
  gap: 20px;
  align-items: center;
}

.box div {
  background-color: #fff100;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--dark-color);
  font-size: 23px;
  font-weight: bold;
}

@media (max-width: 792px) {
  .box div {
    font-size: 40px !important;
    width: 50px !important;
    height: 50px !important;
    flex-shrink: 0;
  }
  .inputGroup{
    flex-direction:column;
    gap:30px;

  }
  .careerContent h2 {
    font-size: 23px !important;
  }
  .box p {
    text-align: center !important;
  }
}

.box p {
  margin: auto 0px;
  font-weight: 600;
  font-size: 18px;
  color: #3d3d3d;
}

.chooses {
  max-width: 80%;
  margin: 10px auto;
}

.choosesBoxes {
  display: flex;
  flex-direction: column;
  gap: 30px;
  justify-content: flex-start;
  align-items: start;
}

.boxContainer {
  max-width: 85%;
  margin: 10px auto;
}

@media (max-width: 1022px) {
  .boxContainer {
    max-width: 100%;
    margin: 10px auto;
  }

  .choosesBoxes {
    margin-left: 0px !important;
  }
}

@media (max-width: 884px) {
  .chooses {
    max-width: 100%;
  }
}

@media (max-width: 830px) {
  .careerContent {
    max-width: 90%;
  }

  .pageContainer {
    padding: 0px 1.5rem !important;
  }
}

@media (max-width: 686px) {
  .careerContent {
    margin: 0px auto;
    width: 100%;
  }

  .careerTitle p {
    max-width: 90%;
  }

  .form {
    max-width: 100%;
  }
}

@media (max-width: 620px) {
  .boxContainer {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
}

@media (max-width: 576px) {
  .careerTitle p {
    max-width: 100%;
  }
}

@media (max-width: 500px) {
  .careerForm {
    padding: 20px 10px;
  }
}

@media (max-width: 465px) {
  .pageContainer {
    padding: 0px 1rem !important;
  }
}
