.me_cotactUs_page {
  /* padding-top: 50px; */
  /* padding-inline: 98px; */
}

.me_contactUs_cotainer {
  margin-top: 22px;
  display: flex;
  /* align-items: center; */
  /* width: calc(100% - 2 * 98px); */
  border-radius: 16px;
  box-shadow: 0px 8px 35px 0px rgba(106, 162, 203, 0.15);
}

.left_content {}

.me_contactUs_cotainer .left_section {
  height: 100%;
  /* width: 100%; */
  /* padding-right: 48px; */
  /* width: 50%; */
}

.me_cotactUs_page.rowDiv .me_contactUs_cotainer .left_section .image {
  height: 97.8% !important;
  margin-top: 0 !important;
}
.me_cotactUs_page.rowDiv {
 
  min-height: 170vh !important;
}
.me_contactUs_cotainer .me_social_icons {
  /* justify-content: center !important; */
  flex-direction: column;
  align-items: flex-start;
  gap: 0 !important;
}
.me_contactUs_cotainer .me_social_icons  .me_social_icons{
  flex-direction: row !important;
  gap: 16px !important;
}
.me_contactUs_cotainer .left_section img {
  width: 68px !important;
  height: 40px !important;
}
.me_cotactUs_page.rowDiv .me_contactUs_cotainer {
  margin-top: 0 !important;
  padding: 0 !important;
  height: 100% !important;
}
.me_contactUs_cotainer .left_section img {
  width: 100%;
  height: 100% !important;
}

.left_content {
  /* position: absolute; */
  background: url("./../../assets/contactUsImage.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top right;
  top: 0;
  left: 0;
  width: 100%;
  padding: 10px 18px 40px;
  /* border: 1px solid green; */
  height: 100% !important;
}


.map_info {
  display: flex;
  flex-direction: column;
}

.left_content .contact_map {
  width: 100%;
  height: 544px;
}

.left_content .contact_info {
  margin-top: 40px;
  margin-left: 16px;
  width: 419px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.left_content .contact_info .contact_info_row {
  display: flex;
  gap: 10px;
  margin-bottom: 42.5px;
}

.left_content .contact_info .contact_info_row .info {
  color: var(--dark, #002f59);
  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.me_social_icons {
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
}

.me_social_icons .icon {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: #fff;
}

.Right_section {
  /* padding: 27px 60px 27px 38px; */
  margin-left: 38px;
  /* border: 1px solid red; */
  padding-right: 60px;
  flex-grow: 1;
}

.Right_section .title {
  color: var(--dark, #002f59);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 600;
  line-height: 120%;
  /* 48px */
  margin-top: 27px;
}

.Right_section .fill_up {
  margin-top: 16px;
  color: var(--dark, #002f59);
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}

.me_contact_form {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.me_contact_form .me_from_input {
  width: 100%;
}

.me_contact_form .req_star {
  color: var(--secondary-basma, #ff2c17);
  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.me_contact_form .me_form_input {
  width: 100%;
}

.me_contact_form .me_form_input .me_input_lable {
  color: #035297;
  font-size: 22px;
  width: 100%;
  margin-bottom: 8px;
}

.me_contact_form .me_form_input>input,
.me_contact_form .me_form_input>select,
.me_contact_form .me_form_input>textarea {
  color: #8a8a8a;
  /* caption */
  font-family: Amaranth;
  font-size: 14px;
  width: 100%;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 16.8px */
  padding-block: 19px;
  padding-inline: 22px;
  border-radius: 8px;
  background: #f0f0f0;
  border: none;
  outline: none;
}

.Right_section {
  padding-bottom: 30px;
}

.mail_number {
  display: flex;
  align-items: center;
  gap: 16px;
}

.me_send_button button,
.CareersBtn {
  width: 100%;
  border-radius: 8px;
  background: var(--primary-orouba, #035297);
  border: none;
  outline: none;
  padding: 11px 244px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--white, #fff);
  /* H1-bold */
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 33.6px */
}

.me_contactUs_cotainer {
  border-radius: 16px;
  background: #fff;

  /* main shadows */
  box-shadow: 0px 8px 35px 0px rgba(106, 162, 203, 0.15) !important;
}

.left_section,
.me_contactUs_cotainer .left_section .image {
  height: 980px !important;
  /* padding: 10px; */
}

.me_contactUs_cotainer .left_section .image,
.me_contactUs_cotainer .left_section,
.Right_section {
  /* max-width: 584px !important; */
  width: min(584px, 100%) !important;
}

.me_contactUs_cotainer .left_section .image,
.me_contactUs_cotainer .left_section,
.Right_section {
  /* max-width: 584px !important; */
  width: 100% !important;
  max-width: 100% !important;
  margin-top: 25px;
}

.me_contactUs_cotainer .me_social_icons {
  justify-content: center !important;
}

.me_contactUs_cotainer {
  margin: 38px 0 !important;
}

@media (max-width: 1410.98px) {
  .me_contactUs_cotainer {
    display: flex;
    /* flex-direction: column-reverse; */
    align-items: center;
    justify-content: center;
  }

  .left_section {
    width: 100%;
  }

  .map_info {
    display: flex;
    /* flex-direction: row; */
  }

  .Right_section {
    width: 100%;
  }

  .me_contactUs_cotainer .left_section .image {
    width: 100%;
    /* margin-top: 20px; */
  }

  .me_contactUs_cotainer .left_section .image {
    width: 100%;
    height: 615px;
    overflow: hidden;
  }
}


.me_contactUs_cotainer {
  margin: 38px 0 !important;
  padding-bottom: 10px;
}

.me_contactUs_cotainer>* {
  width: 45% !important;
}