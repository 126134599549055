.recipe_details .features .feature_item {
  width: 100%;
  margin: auto;
  display: flex;
  align-items: start;
  gap: 10px;
  justify-content: center;
}

.features .feature_item {
  text-align: start;
}

.features .feature_item h5 {
  color: var(--dark, #002F59);

  /* H1-bold */
  font-family: Amaranth;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 33.6px */
}

.features .feature_item p {
  color: var(--dark, #002F59);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}
