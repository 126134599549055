.export_catalog_page {
  min-height: 80vh;
  margin-top: 45px;
  padding: 0px 120px;
}

.cat_expo_ban {
  margin-top: 60px;
  display: flex;
  align-items: start;
  justify-content: space-around;
  gap: 40px;
  margin-bottom: 60px;
  flex-wrap: wrap;
}

.cat_expo_ban .left {
  width: 44%;
}

.cat_expo_ban .right {
  width: 45%;
}

.cat_expo_ban .right img {
  max-width: 100%;
}

.cat_expo_ban .left h5 {
  font-family: Amaranth;
  font-size: 35px;
  font-weight: 700;
  line-height: 42px;
  text-align: left;
  color: rgba(3, 82, 151, 1);
  margin-bottom: 15px;
}

.cat_expo_ban .left p {
  font-family: Amaranth;
  font-size: 23px;
  font-weight: 400;
  line-height: 34.5px;
  text-align: left;
  color: rgba(0, 47, 89, 1);
}
.cat_expo_ban .left em:hover {
  background: #e2ecff !important;
  color: black !important;
  border-color: #e2ecff !important;
}
.cat_expo_ban .left em {
  width: 233px;
  font-style: normal !important;
  height: 52px;
  padding: 10px;
  display: flex;
  gap: 0px;
  border: 1px 0px 0px 0px;
  background: rgba(3, 82, 151, 1);
  color: white;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  transition: 0.4s ease-in-out;
}

.export_catalog_page .breadcrumb {
  padding-left: 20px;
}

@media (max-width: 1100px) {
  .export_catalog_page {
    padding: 0px 100px;
  }
}

@media (max-width: 992px) {
  .cat_expo_ban .left {
    width: 100%;
  }

  .cat_expo_ban .right {
    width: 100%;
    order: -1;
  }
}

@media (max-width: 767px) {
  .export_catalog_page {
    padding: 0px 60px;
  }

  .cat_expo_ban .left h5 {
    color: var(--primary-orouba, #035297);

    font-family: Amaranth;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%;
  }

  .cat_expo_ban .left p {
    color: var(--dark, #002f59);

    /* body */
    font-family: Amaranth;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    /* 27px */
  }
}

@media (max-width: 565px) {
  .export_catalog_page {
    padding: 0px 20px;
  }

  .cat_expo_ban .left em {
    width: 100%;
  }
}


.arVersion .brandsImages.rec_brand_imgs.active .swiper-wrapper{
  justify-content: initial !important;
}