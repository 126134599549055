.hero_section h5 {
  color: var(--dark, #002f59);
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 33.6px */
  margin: 0;
}

.why_us_section p {
  color: var(--dark, #002f59);
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  width: min(600px, 100%);
}

.why_us_section {
  gap: 69px;
  width: 100%;
}

.brands_section.why_us_section{
  gap: 32px !important;
  
}

.brands_section.why_us_section .rowDiv{
  flex-wrap: nowrap !important;
}

.brands_section.why_us_section .rowDiv p{
  font-size: 21px !important;
}



.why_us_section button {
  margin-top: 32px;
}

.why_us_section {
  width: 100% !important;
  margin: 0 !important;
}

.brands_section.hero_section > img {
  width: min(1027px, 100%) !important;
  height: 413px;
  flex-shrink: 0;
}

.brands_section > p {
  width: min(1027px, 100%);
  text-align: center;
  color: var(--white, #FFF);
text-align: center;

/* tile */
font-family: Amaranth;
font-size: 23px;
font-style: normal;
font-weight: 400;
line-height: 150%; /* 34.5px */
}
