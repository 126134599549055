.export_form {
  padding-top: 20px;
  background-image: url("../../../assets/bg_form.png");
  padding: 50px 70px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 50px;
  border-radius: 20px;
}

.export_form form h5 {
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.export_form form p {
  color: var(--primary-orouba, #035297);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-bottom: 25px;
}
iframe#webpack-dev-server-client-overlay{
  display: none !important;
}

.export_form form input {
  padding: 20px;
}

.export_form form input::placeholder {
  text-align: start;
}

.export_form form .submit span {
  width: 100%;
  display: block;
  background: rgba(3, 82, 151, 1);
text-align: center !important;
cursor: pointer;
  /* background-color: var(--sec-color); */
  color: white;
  padding: 20px;
  border-radius: 16px;
  color: var(--white, #FFF);

  /* H1-bold */
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 33.6px */
}

@media (max-width:767px) {
  .export_form {
    padding: 30px;
  }

  .export_form form p {
    width: 240px;
    font-family: Amaranth;
    font-size: 18px;
    font-weight: 400;
    line-height: 27px;
    text-align: left;
  }

  .export_form {
    padding: 40px 25px 30px !important;
  }

  .export_form .submit {
    margin-top: 24px !important;
  }
}

@media (max-width:565px) {
  .export_form form h5 {
    font-size: 22px;
  }

  .export_form form .submit span {
    font-size: 20px;
  }
}

span {
  display: inline-block;
}

@media (max-width:565px) {
  .export_form {
    padding: 20px 15px;
  }
}

.arVersion input, .arVersion textarea{
  text-align: right !important;
}

.arVersion #products_types_info .product_continer_text_data p{
  text-align: center !important;
}


.arVersion #products_types_info .product_continer_text_data.productDetailsPage p{
  text-align: right !important;
}