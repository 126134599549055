.recipe_about {
  padding: 8px 20px 20px 20px;  
  width: 1300px;
  max-width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
}


.recipe_about .left {
  width: 500px;
  max-width: 100%;
  margin: 30px 42px 0 0;
}

.recipe_about .left img {
  width: 100%;
  object-fit: cover !important;
  height: 100%;
}

.recipe_about .right {
  margin: 0px !important;
  margin-top: 40px !important;
  width: calc(100% - 600px);
}

.recipe_about .right>div {
  display: flex;
  align-items: start;
  gap: 10px;
  color: var(--dark, #002F59);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}

.recipe_about .right>div * {
  margin: 0px;
}

.recipe_about .bottom {
  padding: 8px 0px 0px 0px;  
  /* width: 1300px; */
  /* margin: 0px 25px; */
  width: 89%;
  color: var(--dark, #002F59);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  margin-top: 30px;
  /* 34.5px */
}


.recipe_about .bottom.active {
  padding: 8px 20px 10px 20px;  
  /* width: 1300px; */
  margin: 0px 25px;
}

.recipe_about .bottom, .recipe_about .right{
  color: var(--dark, #002F59);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
@media (max-width:1000px) {
  .recipe_about {
    justify-content: center;
  }

  .recipe_about .left,
  .recipe_about .right {
    width: 600px;
    max-width: 100%;
    margin: auto;
  }
}

@media (max-width:650px) {
  .recipe_about .bottom {
    width: 100%;
  }
}

@media (max-width:565px) {
  .recipe_about {
    padding: 10px;
  }

  .recipe_about .bottom {
    font-size: 20px;
  }

  .recipe_about .right>div {
    font-size: 20px;
  }
}

@media (max-width:400px) {
  .recipe_about .bottom {
    font-size: 14px;
  }

  .recipe_about .right>div {
    font-size: 14px;
  }
}


.swiper{
  z-index: 1233333 !important;
}