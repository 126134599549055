.brandCategoryName.brandsFilter {
  display: flex;
  align-items: center;
  gap: 32px;
  max-width: 100%;
  overflow-y: auto;
  padding: 10px;
}

/* 
/* .brandCategoryName.brandsFilter::-webkit-scrollbar {
  width: 4px;
}

/*  
.brandCategoryName.brandsFilter::-webkit-scrollbar-track {
  background: #f1f1f16f;
  border-radius: 16px;
  width: 4px;
  height: 3px !important;
} */
/* 
/* 
.brandCategoryName.brandsFilter::-webkit-scrollbar-thumb {
  background: #fff100;
  border-radius: 16px;
  width: 4px;
  height: 3px !important;
} */

.brandsImages.brandCategory {
  max-width: 100% !important;
}

.brandsImages.brandCategory .swiper-slide {
  display: flex;
  justify-content: center;
  margin-bottom: 50px !important;
  align-items: flex-start !important;
  width: 307px !important;
}

.brandOncategory {
  margin-bottom: 50px !important;
}

.reciepe {
  /* max-width: 330px !important; */
}

.brandFilter {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  border: 0.5px solid var(--primary-orouba, #035297);
  color: var(--primary-orouba, #035297);
  background: transparent !important;
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  line-height: 150%;
  transition: 0.5s ease-in-out;
  /* min-width: 144px; */
}

.brandFilter:hover {
  border: 0.5px solid var(--primary-orouba, #035297);
  background: var(--secondary, #fff100) !important;
  color: var(--dark, #002f59);
  font-weight: 700 !important;
}

.brandFilter.active {
  border-radius: 16px;
  border: 0.5px solid var(--primary-orouba, #035297);
  background: var(--secondary, #fff100) !important;
  color: var(--dark, #002f59);
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 700 !important;
  line-height: 120%;
}

.receipe_block.brand_block {
  border-radius: 16px;
  background: #fff;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 8px 35px 0px rgba(106, 162, 203, 0.15) !important;
  padding: 20px 0;
  width: 32% !important;
  transition: 0.5s ease-in-out;
  cursor: pointer;
  max-height: 600px !important;
}

/* .receipe_block.brand_block:hover {
  background: var(--bg-hover);
} */
/* .receipe_block.brand_block:hover h4, .receipe_block.brand_block:hover span, .receipe_block.brand_block:hover span svg{
  color: white;
} */

.receipe_block.brand_block:hover img {
  rotate: -27deg;
  translate: 0 -5px;
}

/* 
} */
.receipe_block.brand_block {
  display: flex;
  flex-direction: column;
  gap: 10px !important;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.recipe_about.rowDiv p,
.recipe_about.rowDiv li,
.recipe_about.rowDiv a,
.recipe_about.rowDiv span,
.recipe_about.rowDiv strong {
  color: var(--sec-color) !important;
}

.recipe_about .left {
  width: 450px !important;
  height: 450px !important;
  
}

.receipe_block.brand_block img {
  width: 230px !important;
  min-height: 269.504px !important;
  max-height: 269.504px !important;
  object-fit: contain;
  /* object-fit: contain; */
  flex-shrink: 0;
  transition: 0.5s ease-in-out;
}

.brandsImagesGrid.brands_block {
  grid-template-columns: repeat(auto-fill, minmax(310px, 3fr)) !important;
  justify-content: flex-start !important;
  justify-items: flex-start !important;
  width: 100%;
}

.brandShadow {
  display: flex;
  flex-direction: column;
  gap: 27px;
}

.learnShadowMore {
  display: flex;
  color: var(--dark, #002f59);

  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
  width: 100%;
  justify-content: center;
  align-items: center;
  text-decoration: none;
}

.product_type_name {
  text-align: center !important;
  height: 130px;
  width: 300px;
}

/* .reciepe_section .swiper-slide{
  margin: 0 !important;
} */

.Swiper {
  display: flex !important; /* Ensure flexbox is enabled */
  justify-content: flex-start !important; /* Align slides to the left */
}
