@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Amaranth:ital,wght@0,400;0,700;1,400;1,700&family=Tajawal:wght@200;300;400;500;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Zain:wght@200;300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&family=Zain:wght@200;300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Beiruti:wght@200..900&family=Cairo:wght@200..1000&family=Zain:wght@200;300;400;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Baloo+Bhaijaan+2:wght@400..800&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
.enVersion *,
.enVersion h1,
.enVersion h2,
.enVersion h3,
.enVersion h4,
.enVersion h5,
.enVersion h6,
.enVersion a,
.enVersion p,
.enVersion span,
.enVersion input,
.enVersion strong,
.enVersion pre {
  font-family: "Amaranth", sans-serif !important;
}


.arVersion *,
.arVersion h1,
.arVersion h2,
.arVersion h3,
.arVersion h4,
.arVersion h5,
.arVersion h6,
.arVersion a,
.arVersion p,
.arVersion span,
.arVersion input,
.arVersion strong,
.arVersion pre, .arVersion button, .arVersion div {
  font-family: "Baloo Bhaijaan 2", sans-serif !important;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.rowDiv {
  margin: auto;
}

.downHeaderDiv {
  margin: 45px auto !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.arVersion .brandsImages.brandCategory .swiper-slide a {
  direction: rtl !important;
}


.arVersion .searchBox{
  flex-direction: row-reverse;
}