.brandsImages {
  max-width: 100% !important;
  position: relative !important;
}

body > div.defaultLayout > div:nth-child(2) > div:nth-child(3) > div.brandsImages.brandsImagesMainPages{
  justify-content: space-between !important;
}

.downHeaderDiv.whoweare p {
  color: var(--sec-color) !important;
}

.brandCategory .reciepe {
  display: flex;
  height: 420px ;
  padding: 333px 13px 36px 13px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  min-width: 300px !important;
  max-width: 320 !important;
  background-size: cover !important;
}

.brandsImages.brandCategory .swiper-slide {
  margin-right: 0px !important;
  align-items: flex-start !important;
}

.brandsImages.brandCategory {
  margin: 0 !important;
}

.brandsImages.brandCategory .swiper-slide p {
  color: var(--white, #fff);
  text-align: center;
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.brandsImages.brandCategory .swiper-wrapper {
  justify-content:space-evenly !important;
}

.downloadHeaderDiv.products_types_page .breadcrumb{
  margin-top: 0 !important;
}

.brandsImages.brandCategory .swiper-slide a {
  color: var(--white, #fff) !important;
  font-family: Cairo !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 120% !important;
  /* 16.8px */
  text-decoration-line: underline !important;
}

.brandsImages.brandCategory .swiper-slide span svg {
  color: white !important;
  /* fill: white !important; */
}

.brandsImages.brandCategory {
  max-width: 100% !important;
  min-width: 100% !important;
  padding: 0 !important;
}

.brandsImages.brandCategory .swiper-slide {
  margin: 4px !important;
  align-items: flex-start !important;
  height: fit-content !important;
}

.boxShadowSection.BrandsShadowSection{
  display: flex;
  flex-direction: column;
  gap: 32px !important;
}

.min_mar {
  margin-top: 0px !important;
}