/* Breadcrumb.css */

.breadcrumb {
  display: flex;
  align-items: center;
  font-size: 18px;
  margin-bottom: 10px;
}

.breadcrumb span {
  margin-right: 5px;
}

.breadcrumb button {
  background: none;
  border: none;
  cursor: pointer;
  text-decoration: none;
  padding: 0;
  /* font-size: inherit; */
}

.breadcrumb button:hover {
  text-decoration: underline;
}

#wrapper > div.content-page > div > div > div > div.breadcrumb {
  width: 100% !important;
}

.breadcrumb span button {
  margin-right: 5px;
  color: var(--dark, #002f59);
  text-align: right;
  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.breadcrumb span.activeLink span {
  color: var(--primary-orouba, #035297);

  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}

.breadcrumb {
  padding: 20px 0;
}

.breadcrumb {
  max-width: var(--main-width) !important;
  /* margin: auto; */
}

.pord_steps_content>div strong{
  font-weight: 900;
}
.downHeaderDiv.whoweare.rowDiv{
  padding: 0 !important;
}

.pord_steps_content>div p{
  color:var(--sec-color) !important;
  font-weight: 400;
}

.pord_steps_content>div p strong{
  color:#002F59 !important
}


.pord_steps_content>div{
  justify-content: space-between !important;
}
.downHeaderDiv.whoweare.rowDiv{
  margin-top: 0 !important;
}
.downHeaderDiv.whoweare.rowDiv {
  padding: 0 !important;
  gap: 40px !important;
  display: flex;
  flex-direction: column;
}

.AreaRow.mt-5{
  margin: 0 !important;
}

.ProductionSteps + .rowDiv{
  flex-wrap: nowrap !important;
}

.AreaRow .col p{
  width: 100% !important;
}