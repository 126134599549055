.recipe_details .features {
  /* padding: 0px 20px 40px; */
  display: flex;
  justify-content: space-between;
  text-align: center;
  margin-top: 46px;
  row-gap: 20px;
  /* grid-template-columns: repeat(auto-fill, minmax(250px, 4fr)); */
}

@media (max-width:700px) {
  /* .recipe_details .features {
    grid-template-columns: repeat(auto-fill, minmax(250px, 4fr));
  } */
}
