.careerContentstyle {
    margin-top: 45px !important;
}

.enVersion input::placeholder, .enVersion textarea::placeholder {
    text-align: left !important;
}

.arVersion .chooses p {
    text-align: center !important;
}

.arVersion .H2InLarge {
    text-align: center !important;
}