.export_contries {
  padding: 60px 0px;
}

.export_contries .contries .content {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 8px;
}

.export_contries .contries ul {
  list-style: circle;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: var(--main-color);
}

.export_contries .contries li>div:nth-of-type(2) {
  display: flex;
  align-items: center;
}

.export_contries .contries .content div {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
}

.export_contries .contries .content div span {
  color: var(--primary-orouba, #035297);

  /* tilte-bold */
  font-family: Amaranth;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 27.6px */
}

.export_contries .contries .content div .cont_name {
  color: var(--primary-orouba, #035297);

  /* tilte-bold */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 27.6px */
}

.export_contries .contries .content div span {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #002F59;
}

.export_contries .contries .content div span:nth-of-type(1) {
  justify-content: start;
  font-size: 23px !important;
  font-weight: bold !important;
}

@media (max-width:767px) {}

ul.contries{
  display: flex !important;
  align-items: center;
  justify-content: space-between;
  flex-direction: wrap;
}