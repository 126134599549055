.hero_section {
  margin: 40px auto;
}

.hero_section>  img {
  width: min(659px, 100%);
  gap: 71px !important;
}

.hero_texts {
  gap: 16px;
  margin: auto;
}

body > div.defaultLayout > div:nth-child(2) > div.hero_section.map_section.d-flex.justify-content-between.w-full.rowDiv > div{
  margin: 0;
}

.hero_texts h1 {
  display: flex;
  gap: 10px;
  color: var(--dark, var(--sec-color));
  font-family: Amaranth;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 60px */
}

.hero_texts h1 span {
  color: var(--main-color);
}

.hero_texts p {
  color: var(--dark, var(--sec-color));

  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 27px */
}
.hone_sections_button {
  display: flex;
  width: 193px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  background: var(--heading-color);
  color: var(--dark, var(--sec-color));
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 21.6px */
  border: 2px solid #ffd53e;
  transition: .4s ease-in-out;
}

.hone_sections_button:hover {
  background: #ffd53e;
}
