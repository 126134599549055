.export_banner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px;
  gap: 60px;
  flex-wrap: wrap;
}
.export_banner .texts, .export_banner .texts p {
  width: min(800px, 100%);
  font-size: 20px !important;
}
.export_banner .texts p{
  width: 100% !important;
}
.certifications_page.rowDiv {
  margin-top: 45px !important;
  
}
.certifications_page.rowDiv p {
  text-align:  center !important;
}
.export_page {
  margin-top: 25px;
}


.export_banner .left, .export_banner .right {
  width: 40%;
}
.export_banner .right {
  width: 49%;
}

/* .export_banner .right {
  width: min(606px, 100%)
} */


@media (max-width:1300px){
  .export_banner .left {
    width: 40% !important;
  }
  
  .export_banner .right {
    width: 40% !important
  }
  
}

.export_banner .left img {
  width: 100%;
}

.export_banner * {
  margin: 0px;
}

.export_banner .right h5 {
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 60px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.export_banner .right .texts p {
  color: var(--primary-orouba, #035297);
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  line-height: 1.3em !important;
  /* 34.5px */
}

.export_banner .right .texts p:first-of-type {
  margin-bottom: 4px;
}


@media (max-width:1200px) {
  .export_banner .right h5 {
    font-size: 33px;
  }

  .export_banner .right .texts p {
    font-size: 19px;
  }
}

@media (max-width:992px) {
  .export_banner .right {
    width: 100%;
  }

  .export_banner .left {
    width: 500px;
    max-width: 100%;
    margin: auto;
  }

  .export_banner .right h5 {
    text-align: center;
    margin-bottom: 5px;
  }

  .export_banner .right .texts p {
    text-align: center;
    /* width: 500px; */
    /* margin: auto; */
  }
}


button.hoverable.certificationsHoverable {
  background: var(--primary-orouba, var(--main-color));
  color: white !important;
  margin: 90px auto 0;
  display: flex;
width: 264px;
height: 50px;
padding: 10px;
justify-content: center;
align-items: center;
border-radius: 16px;
background: var(--primary-orouba, #035297);
}

.swiper-slide{
  /* margin: auto !important; */
  /* max-width: 300px !important; */
}