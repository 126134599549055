.arVersion .headerBackground .rowDiv {
  direction: ltr;
}
.headerBackground .rowDiv {
  display: flex;
  justify-content: space-between;
}

.headerBackground {
  border-radius: 0px 0px 16px 16px;
  background: #035297;
  /* padding: 0 30px; */
}

.logo img {
  width: 100%;
  margin-top: 54px;
  object-fit: cover;
}
.logo {
  object-fit: cover;
  width: 136px;
  height: 131px;
  flex-shrink: 0;
}

.headerImage {
  pointer-events: none;
}

.headerImage {
  max-height: 100%;
  overflow: hidden;
  position: absolute;
  width: 500px;
  right: 0%;
  height: 100%;
  z-index: 1;
  top: px;
}
.HeaderIcons{
  z-index: 7;
  margin-top: 24px;
}
header {
  position: relative;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 12;
  transition: all 0.3s ease-in-out;
}

header.sticky {
  position: fixed;
  top: 0;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Optional: for a shadow effect */
}



.headerImage img {
  position: absolute;
  right: 0;
  top: 0;
  height: 300px;
  width: 100%;
}

.enVersion {
  right: auto;
  left: 0;
}
/* 
.enVersion .headerImage{
  right: auto;
  left: -3%;
} */
.headerImage img:first-child {
  top: -40px;
}