body.arVersion {
  direction: rtl;
  text-align: right !important;
}
.rowDiv {
  width: var(--main-width);
}

body.arVersion .swiper,
body.arVersion .swiper-wrapper {
  direction: ltr !important;
}

body.arVersion h4,
body.arVersion h2,
body.arVersion p {
  text-align: right !important;
}

body.arVersion .WhoWeAre col p,
body.arVersion .WhoWeAre col h5,
body.arVersion p.copyright {
  text-align: center !important;
}


span.list_toggle {
  /* display: inline-block; */
  width: fit-content;
}

.row.d-flex {
  display: flex !important;
  flex-wrap: nowrap;
  align-items: center;
}