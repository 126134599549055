.sidebar_reciepe {
  display: flex;
  width: -moz-fit-content;
  width: fit-content;
  padding: 23px 30px 74px 39px;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px 8px 8px 8px;
  background: var(--main-color);
  gap: 48px;
  cursor: pointer;
  min-width: 230px !important;
}

span.receipeName.active {
  color: var(--secondary, #fff100);

  /* H1-bold */
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
}

.sidebar_reciepe span {
  color: var(--white, #fff);

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%; /* 34.5px */
}
.brandsImagesGrid {
  grid-template-columns: repeat(auto-fill, minmax(330px, 3fr));
  display: grid;
  width: 100%;
  justify-content:flex-start !important;
  gap: 38px ;
}

.brandsImagesGrid img {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover;
  border-radius: 8px;
}

.item_row{
  width: min(310px, 100%) !important;
  /* border-radius: 15px; */
  height: 402px !important;
}

.recipe_banner .breadcrumb {
  width: 60% !important;
  margin: 0 auto;
}
@media (max-width: 1450px) {
  
  .menuToggle a{
    font-size: 16px !important;
  }
  .brandsImagesGrid {
    grid-template-columns: repeat(auto-fit, minmax(340px, 0fr)) !important;
    gap: 18px;
  }
  
  .brandsImagesGrid.brands_block{
    grid-template-columns: repeat(auto-fill, minmax(310px, 3fr)) !important;
  }

  .recipe_banner .left .content h5 {
    font-size: 40px !important;
  }

  .brandsImagesGrid img {
    width: 290px !important;
  }
}

.brandsImages.brandsImagesGrid{
  flex-wrap: wrap !important;
}
@media (max-width: 1200px) {
  
  
}
.receipe_block {
  gap: 24px !important;
  display: flex;
  flex-direction: column;
  /* max-width: 330px !important; */
  min-height: 300px !important;
  justify-content: flex-start !important;
}
.receipe_block h4, .receipe_block p {
  text-align: start !important;
}
.receipe_details {
  max-width: 100% !important;
}

.receipe_block img, .receipe_block > *{
  width: 300px !important;
}

.gridDiv {
  gap: 24px;
  flex-wrap: nowrap;
  display: flex;
  align-items: flex-start;
}

.categoryName {
  margin-top: 56px;
  margin-bottom: 40px;
}

.receipe_details h4 {
  color: var(--dark, #002f59);
  margin-bottom: 16px;
  /* H1-bold */
  font-family: Amaranth;
  font-size: 26px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%; /* 33.6px */
}

.receipe_details .rowDiv {
  max-width: 100%;
  display: flex;
  justify-content: space-between;
}

.receipe_details {
  max-width: 320px;
}

.receipe_details .rowDiv {
  margin: 0px;
}


