.rowDiv.bannerDiv {
  /* margin: 40px auto; */
  width: 100% !important;
}

.rowDiv.bannerDiv > * {
  width: 100%;
}

.rowDiv.bannerDiv button {
  display: flex;
  width: 250px;
  height: 72px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  /* border-radius: 16px; */
  border: 3px solid #e81e23;
  background: var(--red, #e81e23);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  color: var(--white, #fff);
  text-align: right;
  font-family: Tajawal;
  font-size: 22px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 33.6px */
  border: none;
  outline: none;
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.rowDiv.bannerDiv button:hover {
  background: transparent;
}

.banner h4 {
  width: fit-content !important;
  color: var(--light-green, #f7fff8);
  text-align: start;

  /* web-h1 */
  font-family: Tajawal;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  margin-top: 0;
}
.swiper-cube-shadow{
  display: none !important;
}
* {
  box-shadow: none !important;
}

.banner {
  /* min-height: 506px; */
  display: flex;
  /* width: 90%; */
  margin:0  auto;
  justify-content: center;
  justify-content: flex-start !important;
  align-items: center;
  align-items: flex-start !important;
  flex-direction: column;
  /* border-radius: 16px; */
  width: 100% !important;
  /* max-width: 1450px; */
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center bottom !important;
  box-shadow: inset !important;
  margin-top: -10px !important;
}

.rowDiv.bannerDiv {
  margin-top: -14.4px;
}

/* .banner img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
} */

@media (max-width: 767px) {
  .ideas .banner p {
    font-size: 30px;
  }


  .ideas .banner button {
    font-size: 23px;
  }
}

@media (min-width:1100px) {
  .rowDiv.bannerDiv{
    /* margin-top: 102px; */
  }
}

@media (max-width:1100px) {
  .banner {
    background-size: contain !important;
  }

  .rowDiv{
    padding: 10px;
  }
}

.banner video{
  object-fit: cover !important;
  width: 100%;
}