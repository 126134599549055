.certifications_page>h4 {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--primary-orouba, #035297);
  font-family: Amaranth;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.certifications_page>h4 span:first-of-type {
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.certifications_page .texts {
  margin-top: 50px;
  text-align: center;
  width: 1100px;
  margin: 40px auto;
  max-width: 100%;
}

.certifications_page .texts * {
  margin: 0px;
}

.certifications_page .texts p {
  color: var(--dark, #002F59);
  text-align: center;
  font-family: Cairo;
  font-size: 19px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}

@media (max-width:565px) {

  .certifications_page>h4,
  .certifications_page>h4 span:first-of-type {
    font-size: 20px;
  }

  .certifications_page .texts p {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .certifications_page .texts {
    margin-top: 20px;
  }
}
