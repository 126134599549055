.HeaderIcons {
  display: flex;
  /* width: 141px; */
  align-items: center;
  gap: 13px;
  flex-shrink: 0;
  z-index: 12;
  /* overflow: hidden; */
}

.HeaderIcons > * {
  cursor: pointer;
}
.row.d-flex {
  z-index: 3;
}

.HeaderIcons sup,
.HeaderIcons sub {
  font-size: 14px;
  font-weight: 700;
  color: white;
  background: var(--main-color);
  position: absolute;
  top: -15px;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  left: -17px;
  border-radius: 50px;
  z-index: 12;
}

.HeaderIcons sub {
  right: -17px;
  left: auto;
  top: auto;
  bottom: -15px;
}

.headerBackground .HeaderIcons .menuToggle {
  margin: 0 !important;
}

.hoverable {
  display: flex;
  min-width: 176px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: var(--primary-orouba, var(--main-color));
  transition: 0.4s ease-in-out;
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border: none;
  outline: none;
  border-radius: 8px;
  background: var(--white, #fff);
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15);
}

.arVersion .HeaderIcons .hoverable {
  /* margin-left: 32px !important; */
}

.enVersion .HeaderIcons .hoverable {
  /* margin-right: 32px !important; */
}

.hoverable:hover {
  background: #002f59 !important;
  color: white;
}

.menuToggle .menu a:hover {
  color: #1e6fe4 !important;
}

.enVersion .HeaderIcons {
  flex-direction: row-reverse;
  left: auto !important;
  right: -10% !important;
}

.arVersion .headerImage {
  right: auto;
  left: -10%;
  top: -10px;
  transform-origin: center;
  rotate: 180deg;
}

.arVersion .headerImage.active {
  rotate: 0deg;
  left: -15%;
  top: -2px;
}

.list_toggle {
  display: none;
}

.arVersion .headerBackground .rowDiv {
  flex-direction: row-reverse;
}

.menu_Toggle {
  align-items: center;
  justify-content: center;
  display: flex;
}

.brandsImages .swiper-slide {
  max-width: 402px !important;
  width: min(402px, 100%) !important;
  height: 552px !important;
}

.brandsImages .swiper-slide .reciepe {
  width: 100%;
  height: 100%;
}

.brandsImages .swiper-slide img {
  width: 100%;
  min-height: 100%;
  object-fit: cover;
}

@media (max-width: 992px) {
  

  .me_contactUs_cotainer {
    display: flex;
    /* flex-direction: column-reverse; */
  }
 
  .standard {
    width: 243px !important;
  }

  .standard p{
    font-size: 15px !important;
  }


  /* .menu_Toggle .menuLabel>a {
    color: black !important;
  } */

  .rowDiv.bannerDiv {
    padding: 0 !important;
  }
}

@media (max-width: 992px) {
  .headerBackground .rowDiv {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }

  .headerImage {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    width: 300px;
    right: -8%;
    height: 100%;
    z-index: 1;
    top: 60px;
  }
}

@media (max-width: 630px) {
  .reciepe.rowDiv {
    margin-top: 230px !important;
  }

  .category {
    margin: auto !important;
  }
}

@media (max-width: 1100px) {
  .menu_Toggle .menuLabel.menuLabelleb:hover .menu {
    /* top: 0 !important; */
  }
}

.rowDiv.bannerDiv .swiper-slide {
  margin-right: 30px !important;
}


/* .searchBox {
  position: fixed;
  background: #00000063;
  width: 100% !important;
  height: 100%;
  top: 0;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 100px !important;
  z-index: 123423567 !important;
} */