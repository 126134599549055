.export_standars {
  margin-top: 60px;
  border-radius: 16px;
  background: #FFF;

  /* main shadows */
  box-shadow: 0px 8px 35px 0px rgba(106, 162, 203, 0.15) !important;
  border-radius: 10px;
  margin-bottom: 40px;
  padding-top: 30px;
}

.iconWithText span:first-child, .iconWithText span img {
  width: 30px !important;
  height: 30px !important;
}

.export_standars>h4 {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--primary-orouba, #035297);
  font-family: Amaranth;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.export_standars>h4 span:first-of-type {
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 60px */
}

.export_standars>p {
  color: var(--dark, #002F59);
  text-align: center;

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  width: 940px;
  margin: auto;
  max-width: 100%;
}

.export_standars .standers {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 10px;
  flex-wrap: wrap;
}

input::placeholder, textarea::placeholder{
  color: #8a8a8a !important;
}

.export_standars .standers .stander {
  display: flex;
  flex-direction: column;
  gap: 5px;
  /* justify-content: center; */
  align-items: center;
  padding: 10px 20px;
  background-image: url("../../../assets/Rectangle\ 29.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 295px;
  width: 285px;
  padding: 30px;
  padding-top: 50px;
  max-width: 100%;
}

.export_standars .standers .stander img {
  width: 80px;
}

.export_standars .standers .stander p {
  text-align: center;
  color: white;
  color: var(--white, #FFF);
  text-align: center;

  /* body-bold */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 21.6px */
}

@media (max-width:767px) {
  .export_standars>h4 {
    font-size: 22px;
  }

  .export_standars>h4 span:first-of-type {
    font-size: 22px;
  }

  .export_standars>p {
    font-size: 17px;
  }
}


span{
  display: inline-block;
}

.arVersion .export_standars p {
  text-align: center !important;
}