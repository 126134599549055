.iconWithText {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: #000;
  text-align: right;

  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%; /* 21.6px */
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}

.contact .iconWithTextTitle {
  color: #000;
  font-family: Tajawal;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 120%; /* 33.6px */
}

.contact {
  margin-bottom: 43px;
}


.iconWithText img{
  width: 30px !important;
  min-height: 30px !important;
  max-height: 30px !important;

}