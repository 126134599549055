.certification_values {
  padding-top: 10px;
  background-image: url("../../../assets/bg_form.png");
  padding: 40px 30px;
  background-size: cover;
  background-repeat: no-repeat;
  margin-bottom: 140px;
  border-radius: 20px;
}

.certification_values .values {
  display: grid;
  row-gap: 10px;
  text-align: center;
  margin-top: 50px;
  grid-template-columns: repeat(auto-fill, minmax(400px, 4fr));
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}

.certification_values h4 {
  color: var(--dark, #002F59);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-align: center;
}

.certification_values>p {
  color: var(--dark, #002F59);
  text-align: center;

  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 34.5px */
}

.certification_values h4 span:nth-of-type(2) {
  color: var(--primary-orouba, #035297);
  font-family: Poppins;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

@media (max-width:565px) {
  .certification_values {
    padding: 10px;
  }

  .certification_values h4,
  .certification_values h4 span:nth-of-type(2) {
    font-size: 20px;
  }

  .certification_values p {
    font-size: 15px;
  }
}


.arVersion .certification_values h4 {
  text-align: center !important;
}