.buildingsContainer>div {
  text-align: start;
}


.buildingsContainer>div p {
  font-size: 18px !important;
}


.buildingsContainer>div p {
  font-size: 18px !important;
}

.AreaRow {
  margin-top: 80px !important;
}

.whowearestyles {
  flex-direction: column;
}

.buildingsContainer>div {
  text-align: start;
  flex-direction: column;
  width: 45%;
}

.buildingsContainer {
  flex-direction: row;
}

img[alt*="building"] {
  width: 100% !important;
  height: 300px;
  margin-bottom: 0 !important;
  object-fit: cover !important;
  border-radius: 8px;
}

.ProductionSteps ul li::marker {
  font-size: 25px !important;
}

.pord_steps_content {
  margin-bottom: 70px;
}

.pord_steps_content>div {
  margin-top: 40px;
  display: flex;
  /* align-items: center; */
  flex-wrap: wrap;
  /* justify-content: space-between; */
  gap: 19px;

}

.pord_steps_content>div.even {
  gap: 66px;

}

.pord_steps_content>div * {
  color: rgba(0, 47, 89, 1);
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 21px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
  /* 25.2px */
  /* text-align: left; */

}

.pord_steps_content>div .rich_text {
  width: 680px;
  margin-top: 70px;
}

.pord_steps_content>div>div {
  margin-bottom: 0px;
}

.pord_steps_content>div>div {
  /* width: 00px; */
  /* width: calc(100% - 550px); */
}

.pord_steps_content>div .img {
  width: 500px;
  max-width: 100%;
}

.pord_steps_content img {
  max-width: 100%;
}

@media (max-width:1500px) {
  .pord_steps_content>div .rich_text {
    width: 570px;
  }
}

@media (max-width:1380px) {
  .pord_steps_content>div .rich_text {
    width: 480px;
  }
}

@media (max-width:1300px) {
  .pord_steps_content>div .rich_text {
    width: 558px;
  }

  .pord_steps_content>div .img {
    width: 400px;
    max-width: 100%;
  }

}

@media (max-width:1273px) {
  .pord_steps_content>div .rich_text {
    width: 460px;
  }

}

/* @media screen {} */

@media (max-width:1000px) {
  .pord_steps_content .odd {
    /* flex-direction: column-reverse; */
  }

  /* .pord_steps_content>div .rich_text {
    width: 500px;
    margin-top: 0px;
  } */
}

@media (max-width:900px) {
  .pord_steps_content .odd {
    /* flex-direction: column-reverse; */
  }

  .pord_steps_content>div.even {
    gap: 12px;
  }

  /* .pord_steps_content>div .rich_text {
    width: 400px;
  } */
}

@media (max-width:1100px) {
  .pord_steps_content>div .rich_text {
    width: 420px;
  }
}

@media (max-width:992px) {
  .pord_steps_content>div .rich_text {
    width: 420px;
    margin-top: 30px;
  }

  .pord_steps_content>div .img {
    width: 300px;
    max-width: 100%;
  }

}

@media (max-width:850px) {
  .pord_steps_content>div .rich_text {
    width: 100%;
  }

  .pord_steps_content .odd {
    flex-direction: column-reverse;
  }
}

@media (max-width:792px) {
  .imgContainer {
    width: 45% !important;
    margin: auto;
    /* background-color: red !important; */
  }

  .imgContainer.col {
    flex: none;
  }
}

@media (max-width:500px) {
  .imgContainer {
    width: 100% !important;
    margin: auto;
    /* background-color: red !important; */
  }

}


.arVersion div.standard_texts p{
  text-align: center !important;
}

.whowearestylesp p , 
.whowearestylesp span {
  color: #002F59 !important;
}

.arVersion .centeralized {
  text-align: center !important;
}