.recipe_banner {
  min-height: 77vh;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin-top: -14px;
}

.recipe_banner .right {
  width: 50%;
  height: 500px !important;
}

.recipe_banner .left {
  width: 50%;
  height: 500px !important;
  background-color: var(--sec-color);
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.productTypeSliderArrow {
  position: absolute;
  top: 50%;
  translate: 0 -50% !important;
  height: 100px;
  z-index: 12233 !important;
  width: 100%;
}
.recipe_banner video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.recipe_banner .left .breadcrumb * {
  color: white;
}

.recipe_banner .left .breadcrumb span:last-of-type>span {
  color: var(--secondary, #FFF100);

  /* body */
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

.recipe_banner .left .content {
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%); */
}

.recipe_banner .left .content h5 {
  color: white;
  color: var(--white, #FFF);
  font-family: Amaranth;
  font-size: 50px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 60px;
  text-align: center;
}

.recipe_banner .left .content button {
  background-color: #FFF100;
  color: var(--primary-orouba, #035297);
  margin: auto;
  /* tile */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  border-radius: 16px;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.10);
  display: flex;
  width: 190px;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 18px;
  /* 34.5px */
}

.recipe_banner .left .content button img {
  width: 20px;
}

@media (max-width:992px) {
  .recipe_banner .left {
    width: 100%;
  }

  .recipe_banner .right {
    width: 100%;
    order: -1;
  }
}

@media (max-width:992px) {
  .recipe_banner .left .content h5 {
    font-size: 23px;
    text-align: center;
  }
}
