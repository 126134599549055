.AreaRow .col p {
  text-align: center !important;
}
@media (min-width: 772px) {
  .contact_info {
    height: auto !important;
  }
}

.standard img,
body
  > div.defaultLayout
  > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
  > div.mt-5.AreaRow
  > div
  > div
  > div
  > img,
.value img,
.export_standars .standers .stander img {
  width: 9.7vh;
  height: 9.7vh;
  object-fit: contain;
}
.brands_section.why_us_section .rowDiv p {
  /* width: fit-content !important; */
  margin: 0 1vh !important;
  .arVersion .menuLabel {
    position: relative;
    margin-right: auto !important;
    /* margin-left: auto; */
    direction: rtl !important;
  }
  .arVersion .menu_Toggle .menuToggle a {
    justify-content: flex-start !important;
  }

  .arVersion .menuToggle a svg {
    rotate: 180deg;
  }
  .single_footer h4 svg {
    rotate: 180deg !important;
  }
  .arVersion .menu_Toggle.active {
    right: 0 !important;
  }
  .arVersion .HeaderIcons .hoverable {
    display: none !important;
  }
}

/* .arVersion .menu_Toggle .menuToggle{
  flex-direction: column-reverse !important;
} */

.arVersion .imageHeader {
  right: 0 !important;
}
.checked {
  display: none;
}
img.backgroundInternal {
  display: none;
}
.receipe_details.brandShadow h4 {
  text-align: center !important;
  min-height: 55px;
  font-size: 23px !important;
}
.enVersion .productTypeSliderArrow button.custom-prev-button,
.enVersion
  .productTypeSliderArrow
  button.custom-prev-button-ProductTypeSmallSlider {
  right: -5.5% !important;
}
.me_contactUs_cotainer {
  margin-top: 0 !important;
}
.brandsImages.brandsImagesGrid {
  align-items: flex-start !important;
}
body > div.defaultLayout > div.footer > div.container > div {
  justify-content: space-between !important;
}
.productTypeSliderArrow button.custom-next-button,
.productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider,
.productTypeSliderArrow button.custom-prev-button,
.productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
  background: #fff100a1;
  border-radius: 62px;
  height: 62px;
  width: 62px;
}
.breadcrumb {
  padding-bottom: 0 !important;
  margin-top: 39px !important;
}
.left_section {
  height: 100% !important;
  margin: 0 !important;
  padding: 0 !important;
}
.me_cotactUs_page.rowDiv .me_contactUs_cotainer {
  margin-top: 0 !important;
  padding: 0 !important;
}
.me_social_icons .icon img {
  object-fit: contain !important;
  height: 50% !important;
  width: 50% !important;
}
.hero_section > img {
  object-fit: contain !important;
}
.productTypeSliderArrow button.custom-prev-button,
.productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
  position: absolute;
  right: -8% !important;
}

.brandFilter {
  white-space: nowrap;
}

.receipe_block {
  justify-content: center;
  align-items: center;
}
.hero_section.d-flex.justify-content-between.align-items-center.w-full.rowDiv.why_us_section {
  align-items: flex-start !important;
}
.product_continer_img {
  width: min(500px, 100%) !important;
}
.brandOncategory
  .hero_section.d-flex.justify-content-between.align-items-center.w-full.rowDiv.why_us_section {
  align-items: center !important;
}

@media (max-width: 1140px) {
  .product_type_img img {
    width: min(310px, 100%) !important;
  }

  .product_continer {
    flex-wrap: nowrap !important;
  }
  .headerImage {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    width: 500px;
    right: -8%;
    height: 100%;
    z-index: 1;
    top: -9px !important;
  }
  button.btn.btn-primary.brandFilter {
    font-size: 14px !important;
  }
  .receipe_block.brand_block {
    gap: 60px !important;
  }
  .receipe_block.brand_block {
    width: 280px !important;
  }
  .receipe_block.brand_block img {
    width: 210px !important;
  }
}

@media (max-width: 1440px) {
  /* .searchBox {
    width: min(624px, 90%) !important;
  } */
  .brandsImages .swiper-slide {
    width: 31% !important;
    height: 450px !important;
  }

  .reciepe_name {
    font-size: 15px !important;
  }

  .brandsImages.brandCategory .swiper-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 50px !important;
    align-items: flex-start !important;
    min-width: 24% !important;
    max-width: 24% !important;
  }
  .brandCategory .reciepe {
    align-items: center;
    background-size: cover !important;
    display: flex;
    flex-direction: column;
    height: 300px !important;
    justify-content: flex-end;
    max-width: 100% !important;
    min-width: 100% !important;
    padding: 350px 13px 36px;
  }
  .brandsImages.brandCategory .swiper-slide p {
    font-size: 18px !important;
  }
  /* .value{
    width: 280px !important;
  } */

  .hero_section.map_section.d-flex.justify-content-between.w-full.rowDiv img {
    margin: 20px auto !important;
  }

  .brandsImages {
    /* padding: 0 20px; */
  }

  .hero_section {
    /* padding: 0  20px 0 0; */
  }

  .hero_section > img {
    width: min(450px, 100%) !important;
  }

  .brandsImages img {
    cursor: pointer;
    /* width: 340px !important; */
    margin: auto;
    min-height: 300px;
  }

  .brandsImagesMainPages img {
    width: 33%;
    border-radius: 15px;
  }

  .why_us_section p {
    font-size: 21px !important;
  }

  .reciepe,
  .brandsImages .swiper-slide {
    max-width: fit-content !important;
  }

  .reciepe_name {
    max-width: 100% !important;
  }
}
.brandsImagesMainPages img {
  /* width: 290px !important; */
  border-radius: 15px !important;
}
@media (max-width: 1285px) {
  .reciepe_name {
    font-size: 15px !important;
  }

  .me_contactUs_cotainer {
    /* flex-wrap: wrap !important; */
    margin: 38px 0 !important;
  }

  .brandsImagesMainPages img {
    width: 33% !important;
    border-radius: 15px;
  }

  /* .searchBox {
    width: min(524px, 90%) !important;
  } */

  .rowDiv.HomeProducts {
    justify-content: center;
  }

  .categories {
    /* flex-direction: column; */
    /* position: absolute; */
    /* top: 190%; */
    background: white;
    width: 100%;
    /* box-shadow: 1px 2px 24px -2px #8080802e; */
    padding: 10px;
    border-radius: 16px;
    background-color: transparent !important;
    z-index: 122123123 !important;
    transition: 0.4s ease-in-out;
    justify-content: start;
    /* display: none; */
  }

  .categories .category {
    display: none;
  }

  .categories .category.cat {
    display: block;
  }

  .standardsImages {
    flex-wrap: wrap;
  }

  .rowDiv {
    /* justify-content: center; */
  }
}

@media (max-width: 1140px) {
  .left_content {
    max-width: 420px;
  }
  .me_cotactUs_page.rowDiv {
    padding: 0;
    width: 94% !important;
  }
  .me_send_button button,
  .CareersBtn {
    padding: 11px 180px !important;
  }
  .Right_section {
    margin-right: 10px !important;
  }
  .left_content {
  }
  .product_continer {
    flex-wrap: wrap !important;
  }

  .banner {
    justify-content: flex-start !important;
    min-height: 0 !important;
  }

  .searchBox {
    width: 90% !important;
    margin: auto;
  }

  /* .HeaderIcons {
    order: -4;
  } */

  :root {
    --main-width: 94%;
  }
}

.brandSmallScreen {
  display: none !important;
}
.rec_name.rowDiv {
  display: none;
}
.single_footer h4 svg {
  display: none !important;
}
@media (max-width: 792px) {
  .brands_section > img {
    height: auto !important;
  }
  .menu_Toggle .menuToggle a {
    display: flex;
    gap: 30px !important;
  }
  .category-custom-prev-button svg,
  .category-custom-next-button svg {
    display: block !important;
  }
  .single_footer h4 svg {
    display: flex !important;
  }
  .single_footer h4 {
    width: 100% !important;
    text-align: start !important;
    cursor: pointer;
    gap: 30px !important;
    display: flex !important;
    align-items: center !important;
  }
  /* .single_footer input:checked  ~ ul,
  .single_footer input:checked  ~ .contact_info {
    max-height: 200vh !important;
  } */

  .single_footer h4 + ul,
  .single_footer h4 + .contact_info {
    height: 0;
    overflow: hidden;
    transition: 0.5s ease-in-out;
  }
  .single_footer h4 {
    width: 100% !important;
    text-align: start !important;
    cursor: pointer;
  }
  .headerImage img {
    top: 20px !important;
  }
  .headerImage img:first-child {
    top: -10px !important;
  }

  .headerBackground {
    border-radius: 0px 0px 16px 16px;
    background: #035297;
    /* padding: 0 30px; */
    height: 137px !important;
    align-items: center !important;
    display: flex !important;
  }
  .headerImage {
    max-height: 100%;
    overflow: hidden;
    position: absolute;
    width: 500px;
    right: -8%;
    height: 100%;
    z-index: 1;
    top: 9px !important;
  }

  .left_content .contact_info .contact_info_row .info {
    max-width: 300px !important;
  }
  .mail_number {
    flex-wrap: wrap;
  }
  .me_cotactUs_page {
    padding-inline: 0 !important;
  }
  .rec_name.rowDiv {
    display: flex !important;
  }
  .brandsImages .swiper-slide {
    height: 450px !important;
  }
  .recipe_banner .right img,
  .recipe_banner .right {
    height: 100%;
    object-fit: cover;
    width: 100%;
    width: 326px !important;
    min-height: 245px !important;
    height: 245px !important;
    max-height: 245px !important;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .recipe_banner {
    min-height: 360px !important;
  }

  .recipe_about .left {
    display: none;
  }
  .recipe_banner .left {
    display: none;
  }
  .recipe_banner .nameOfReciepe {
    display: block !important;
    color: var(--primary-orouba, #035297);

    /* tilte-bold */
    font-family: Amaranth;
    font-size: 23px;
    font-style: normal;
    font-weight: 700;
    line-height: 120%; /* 27.6px */
  }
  ul.contries {
    flex-direction: column !important;
    text-align: start;
    align-items: flex-start !important;
  }
  .brandFilter.active,
  .brandFilter {
    font-size: 14px !important;
  }
  .brandCategoryName.brandsFilter {
    gap: 14px !important;
  }
  .largeScreen {
    display: none !important;
  }
  .smallscreen.exportscreenPlane svg:first-child {
    top: -20px;
    position: relative;
  }
  .export_banner .texts,
  .export_banner .texts p,
  .export_banner .right h5 {
    text-align: start !important;
  }

  .export_certificatios_page {
    flex-direction: column;
    margin: 14px 0 24px !important;
  }

  .export_standars .standers {
    flex-wrap: nowrap;
    overflow: auto;
  }
  .export_standars .standers .stander {
    min-width: 285px !important;
  }

  .export_banner .texts,
  .export_banner .right h5,
  ul.contries {
    padding: 0 12px 0 84px !important;
  }
  .categoryName {
    text-align: center !important;
    font-weight: 900;
  }
  .brandLargeScreen {
    display: none !important;
  }
  .brandSmallScreen {
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100% !important;
  }
  .value p {
    font-size: 13px !important;
  }

  .WhoWeAre_label__qhxkZ h3,
  .WhoWeAre_label__qhxkZ p {
    text-align: start !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .brandsImages.brandsImagesGrid.brands_block {
    justify-content: center !important;
    align-items: center !important;
  }
  .receipe_block.brand_block {
    margin: auto !important;
    width: 150px !important;
    padding: 38px 20px;
  }

  .receipe_block.brand_block img {
    width: 111px !important;
    height: 95px !important;
    min-height: 95px !important;
    max-height: 95px !important;
  }

  a.learnShadowMore span {
    font-size: 14px !important;
  }

  .receipe_details.brandShadow > span svg {
    width: 111px !important;
  }
  .receipe_block.brand_block {
    gap: 24px !important;
  }
  .receipe_block.brand_block h4,
  .receipe_details.brandShadow {
    font-size: 14px !important;
    /* word-break: break-all !important; */
    white-space: pre-wrap;
    max-width: 100% !important;
  }
  .headerBackground .rowDiv {
    width: 94% !important;
  }
  .footer .container .row {
    flex-wrap: nowrap !important;
    /* flex-direction: column !important; */
    gap: 24px !important;
  }

  .footer .container .row > * {
    /* width: 100% !important; */
    justify-content: flex-start !important;
  }
  .headerBackground .rowDiv {
    width: 86% !important;
  }
  .brandsImagesGrid.brands_block {
    grid-template-columns: repeat(auto-fill, minmax(150px, 3fr)) !important;
    justify-content: flex-start !important;
    justify-items: flex-start !important;
    width: 100%;
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  /* Hide scrollbar for Chrome, Safari and Opera */
  ::-webkit-scrollbar {
    width: 0;
    background: transparent; /* Optional: just make scrollbar invisible */
  }
  .breadcrumb {
    display: none;
  }
  /* .category-custom-prev-button svg,
  .category-custom-next-button svg {
    display: none !important;
  } */
  .category-custom-prev-button,
  .category-custom-next-button {
    align-items: flex-start !important;
    justify-content: space-between !important;
  }

  .category-custom-prev-button {
    left: -4vh;
  }

  .category-custom-next-button {
    right: 4vh !important;
    left: auto !important;
  }

  .category-custom-prev-button span,
  .category-custom-next-button span {
    text-align: end !important;
  }
  .categoryName {
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .swiper,
  .swiper-wrapper {
    max-width: 900px;
  }

  .reciepe,
  .brandsImages .swiper-slide {
    width: 300px !important;
  }
  .swiper,
  .swiper-wrapper {
    max-width: 100% !important;
  }
  .imageHeader {
    overflow: hidden;
  }
  .HeaderIcons .hoverable {
    display: none;
  }

  .sidebar_reciepe {
    flex-direction: column !important;
    padding: 10px;
    width: 100%;

    overflow: initial !important;
  }
  .sidebar_reciepe.open {
    max-height: 100vh;
    padding: 20px;
  }
  .sidebar_reciepe {
    flex-direction: column !important;
    padding: 10px;
    width: 100%;
    overflow: initial !important;
    max-height: 0;
    padding: 0;
    overflow: hidden !important;
    transition: 0.5s ease-in-out;
  }
  .logo {
    display: flex;
    align-items: center;
  }
  .rowDiv.gridDiv {
    flex-direction: column;
  }
  .headerBackground .rowDiv {
    justify-content: space-between !important;
    align-items: center !important;
  }
  .brandsImages {
    flex-wrap: nowrap !important;
    /* padding: 0 20px; */
    overflow: auto !important;
    justify-content: flex-start !important;
  }
  .HeaderIcons {
    margin: 0 !important;
  }

  .banner {
    min-height: auto !important;
  }

  .single_footer {
    text-align: center !important;
  }

  .brandsImagesGrid {
    /* justify-content: space-around !important; */
  }

  .rec_name.rowDiv {
    margin: 0px !important;
    padding: 0 !important;
  }

  .rowDiv.gridDiv {
    gap: 10px;
  }

  .recipe_about.rowDiv p,
  .recipe_about.rowDiv li,
  .recipe_about.rowDiv a,
  .recipe_about.rowDiv span,
  .recipe_about.rowDiv strong {
    color: var(--sec-color) !important;
    font-size: 17px !important;
    font-weight: 400 !important;
    line-height: 1.8em !important;
  }
  .brandsImages.brandCategory .swiper-slide {
    margin-bottom: 24px !important;
  }
  .hero_section.standard_section.d-flex.justify-content-between.flex-column.w-full.homeStandard.rowDiv {
    flex-wrap: nowrap !important;
    margin-bottom: 10px !important;
  }
  .viewAllBtn {
    margin-bottom: 24px !important;
  }
  .hero_section {
    margin: 40px auto !important;
  }
  .standardsImages {
    justify-content: flex-start !important;
    align-items: center !important;
    flex-wrap: nowrap;
    overflow: auto !important;
  }

  button.custom-prev-button-ProductTypeSmallSlider,
  button.custom-next-button-ProductTypeSmallSlider {
    display: none !important;
  }
  .me_send_button button,
  .CareersBtn {
    padding: 11px 0;
    max-width: 100% !important;
    min-width: 100%;
    width: 100% !important;
  }
  .product_continer_text_data h1,
  .product_continer_text_data p {
    font-size: 18px !important;
    margin-top: 24px !important;
  }

  .downloadHeaderDiv.products_types_page
    .ProductTypeSmallSlider
    .brandsImages
    .swiper-slide {
    height: auto !important;
  }
  .product_continer_img {
    width: min(310px, 100%) !important;
  }
  .px-4.my-5.text-start.downHeaderDiv.rowDiv {
    padding: 0 !important;
  }

  .hero_section {
    margin: 20px 0 !important;
  }

  .hero_texts h1,
  .hero_texts a span:first-child {
    font-size: 17px !important;
  }
  .hero_texts a span:first-child {
    font-size: 14px !important;
  }
}

@media (max-width: 500px) {
  /* .swiper, .swiper-wrapper {
    max-width: 100%;
    width: 100% !important;
}
.brandsImages{
  width: 100% !important;
} */
}

@media (max-width: 692px) {
  .LeftMiddleHeader {
    gap: 10px !important;
    width: 100%;
    justify-content: space-between;
  }

  .rowDiv {
    gap: 10px !important;
  }
}

@media (max-width: 484px) {
  input::placeholder {
    font-size: 14px !important;
  }

  /* .HeaderIcons {
    order: -4;
  } */

  .logo {
    width: 140px !important;
    height: fit-content !important;
  }
}

.categoy_subcats .filters {
  flex: 1;
}

.brandsImages {
  display: flex;
  gap: 14px;
  /* justify-content: space-between; */
}

.brandsImages img {
  cursor: pointer;
}

.swiper {
  margin: 0 !important;
}
.brandsImages {
  /* justify-content: space-between !important; */

  margin: 0 20px;
}
@media (max-width: 992px) {
  .brandsImages {
    /* justify-content: space-between !important; */

    margin: 0 20px;
  }
  .receipe_block.brand_block {
    gap: 20px !important;
  }
  .receipe_block.brand_block {
    width: 250px !important;
  }
  .receipe_block.brand_block img {
    width: 190px !important;
  }
  button.btn.btn-primary.brandFilter {
    font-size: 14px !important;
  }
  .receipe_block .item_row img,
  .receipe_block .item_row {
    height: 290px !important;
  }
  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 4px !important;
    display: flex;
    flex-direction: column;
    width: 170px !important;
  }
  .brandsImages {
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    flex-wrap: wrap;
    gap: 12px !important;
  }

  .brandsImages img {
    width: min(330px, 100%);
    margin: auto;
  }
  .product_type_img img {
    width: min(290px, 100%) !important;
  }
  .product_continer_img {
    width: min(310px, 100%) !important;
  }
  .product_continer {
    flex-wrap: nowrap !important;
  }
}

.hero_section {
  /* overflow: hidden !important; */
  /* padding: 0  20px 0 0; */
}
@media (min-width: 792px) {
  .smallscreen.exportscreenPlane {
    display: none !important;
  }
}
@media (max-width: 1150px) {
  .header .headerBackground .rowDiv {
    width: 96% !important;
    justify-content: space-between !important;
  }
  .recipe_about .left {
    margin: 10px !important;
  }
  .sidebar_reciepe {
    min-width: 170px !important;
    min-width: 190px !important;
  }
  .recipe_banner > * {
    width: 50% !important;
  }
  .recipe_banner {
  }
  span.receipeName,
  span.receipeName.active {
    font-size: 19px !important;
  }
  .footer .row {
    justify-content: space-evenly !important;
  }
  .left_content .contact_info .contact_info_row .info {
    max-width: 300px;
    color: var(--dark, #002f59);
    font-family: Amaranth;
    font-size: 18px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
  }
  .hero_texts h1 {
    font-size: 20px !important;
  }
  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 24px !important;
    display: flex;
    flex-direction: column;
    width: 290px !important;
  }
  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
  }
  .receipe_block h4,
  .receipe_block p {
    font-size: 21px !important;
  }
  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 24px !important;
    display: flex;
    flex-direction: column;
    width: 200px !important;
  }
  .receipe_block .item_row img,
  .receipe_block .item_row {
    height: 300px !important;
  }
  /* .export_certificatios .certifications {
 
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr)) !important;
  } */
  /* .export_certificatios .certifications > *, .export_certificatios .certifications img{
    width: 160px !important;
  } */
}

@media (max-width: 1200px) and (min-width: 992px) {
  .categoryName {
    text-align: start !important;
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .downloadHeaderDiv.products_types_page .hero_section {
    margin: 20px auto !important;
    width: 94% !important;
  }
  .productTypeSliderArrow button.custom-prev-button,
  .productTypeSliderArrow button.custom-next-button,
  .productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider,
  .productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
    display: none !important;
  }
  .why_us_section {
    gap: 39px !important;
    width: 100% !important;
  }
  .defaultLayout .rowDiv.gridDiv .brandsImages.brandsImagesGrid {
    display: flex !important;
    gap: 20px;
    margin: 0 10px;
  }
  .hero_section > img {
    width: min(350px, 100%) !important;
  }
  .reciepe_section .hero_texts {
    width: 100% !important;
  }

  .container {
    max-width: 100% !important;
  }
  .rowDiv {
    padding: 0 !important;
  }
  .standard {
    width: 263px !important;
  }
  .hero_texts {
    padding: 10px 0 !important;
  }
  .hero_texts {
    width: min(450px, 100%) !important;
  }
  .hero_texts h1 {
    font-size: 20px !important;
  }
  .hero_texts {
    width: min(400px, 100%) !important;
  }
  .brandCategory .reciepe {
    display: flex;
    height: 340px !important;
    padding: 13px 13px 16px 13px !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: 100% !important;
    min-width: 100% !important;
    background-size: cover !important;
  }
  .defaultLayout .brandsImages.brandCategory .swiper-slide p {
    font-size: 14px !important;
  }
  .brandsImages.brandCategory .swiper-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 50px !important;
    align-items: flex-start !important;
    min-width: 24% !important;
    max-width: 24% !important;
  }
  .hero_texts p,
  .why_us_section .hero_content p {
    font-size: 15px !important;
  }
  .products_types_info .product_continer {
    justify-content: center !important;
    gap: 20px !important;
    flex-wrap: wrap !important;
  }
  .why_us_section .hero_content p {
    font-size: 14px !important;
    margin: 0px !important;
  }
  button.hone_sections_button.d-flex {
    margin-top: 16px !important;
  }
  .hero_section > img {
    width: min(300px, 100%) !important;
  }
  .why_us_section button.hone_sections_button.d-flex,
  .why_us_section button {
    margin-top: 12px !important;
  }
  .hero_section h5 {
    font-size: 20px !important;
  }

  .why_us_section p {
    font-size: 14px !important;
  }
}

.products_types_info .product_continer {
  justify-content: center !important;
  gap: 10px;
  flex-wrap: nowrap !important;
}

.products_types_info .product_continer > * {
  width: 50%;
}
.reciepe_section .hero_texts {
  width: 100% !important;
}
@media (max-width: 1150px) {
  .recipe_about .right ul li {
    font-size: 17px !important;
  }
  .recipe_about .left,
  .recipe_about .right {
    width: 45% !important;
  }
  .menuLabel {
    position: relative;
    margin-right: 10px !important;
  }
  .export_banner.rowDiv > * {
    width: 45% !important;
  }
  .export_banner.rowDiv {
    flex-wrap: nowrap !important;
  }
  .whowearestyles .col p {
    font-size: 17px !important;
  }
  .brands_section.why_us_section .rowDiv p {
    font-size: 14px !important;
  }
}
.header .headerBackground .rowDiv {
  width: 86% !important;
  justify-content: space-between !important;
}
.imageHeader {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 100%;
  right: -8% !important;
}
@media (max-width: 992px) and (min-width: 792px) {
  .categoryName {
    text-align: start !important;
    margin-top: 36px;
    margin-bottom: 30px;
  }
  .productTypeSliderArrow button.custom-prev-button,
  .productTypeSliderArrow button.custom-next-button,
  .productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider,
  .productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
    display: none !important;
  }
  .footer .container .row > *,
  .footer .container .row > * > * {
    /* width: 200px !important; */
  }
  body > div.defaultLayout > div.footer > div.container > div {
    justify-content: center !important;
  }
  .footer .container .row .footer_element.single_footer_width {
    width: 290px !important;
  }
  .header .headerBackground .rowDiv {
    width: 96% !important;
    justify-content: space-between !important;
    flex-wrap: nowrap !important;
    align-items: baseline !important;
  }
  .header .headerBackground .rowDiv .menuLabel > label a {
    font-size: 13.4 !important;
  }
  /* .imageHeader .headerImage img {
    top: 0px !important;
  } */

  .menuToggle a {
    font-size: 14px !important;
  }
  .container {
    max-width: 100% !important;
  }
  .single_footer.single_footer_address .info,
  .single_footer h4,
  .single_footer ul li,
  .single_footer ul li a {
    justify-content: flex-start !important;
    text-align: start !important;
    font-size: 16px !important;
  }
  .brandsImages {
    gap: 8px !important;
  }
  .hero_texts {
    margin: 0 !important;
  }
  .hero_section > img {
    width: min(350px, 100%) !important;
  }
  .cat_expo_ban > *,
  .cat_expo_ban > * > * {
    width: 300px !important;
    font-size: 14px !important;
  }
  .single_footer h4 {
    font-size: 16px !important;
  }
  .menu_Toggle .menuLabel.menuLabelleb:hover .menu {
    top: 104px !important;
  }
  .hero_texts p {
    font-size: 14px !important;
  }
  .hero_section h5 {
    font-size: 20px !important;
  }
  .why_us_section .hero_content p {
    font-size: 13.4px !important;
  }
  .why_us_section button {
    margin-top: 0px !important;
  }
  .standardsImages {
    margin: auto !important;
  }
  .single_footer {
    /* width: 280px !important; */
  }
  .hero_texts {
    width: min(400px, 100%) !important;
  }

  .hero_texts {
    padding: 10px 0 !important;
  }

  .hero_section > img {
    width: min(350px, 100%) !important;
  }
  .hero_texts {
    margin: auto !important;
  }
  .hero_section > img {
    width: min(300px, 100%) !important;
  }
  .brandsImagesMainPages img {
    width: 240px !important;
    border-radius: 15px;
  }
  .hero_section {
    margin: 20px 0 !important;
    width: 100% !important;
  }
  .downloadHeaderDiv.products_types_page .hero_section {
    margin: 20px auto !important;
    width: 94% !important;
  }

  .hero_section > img {
    max-width: 350px !important;
  }
  .brandsImages {
    /* justify-content: space-between !important; */
    align-content: center !important;
    justify-items: center !important;
    width: 100% !important;
    /* margin: auto !important; */
  }
  .brandsImagesMainPages img.brandImage {
    width: 32.5% !important;
    border-radius: 15px;
  }
  /* .imageHeader .headerImage img {
    top: 29px !important;
  } */
  .imageHeader .headerImage img:first-child {
    top: 33px !important;
  }
  .why_us_section {
    gap: 30px !important;
  }
  .boxShadowSection .hero_section > img {
    max-width: 300px !important;
  }

  .footer .container .row > * {
    width: 150px !important;
    /* width: 100% !important; */
    justify-content: flex-start !important;
  }

  .single_footer.single_footer_address > img {
    width: 120px !important;
    height: auto !important;
  }
}

@media (max-width: 992px) {
  img[alt*="building"] + p,
  img[alt*="building"] + div p {
    font-size: 13px !important;
  }

  img[alt*="building"] {
    width: 200px;
    object-fit: contain;
    margin-bottom: auto;
  }
  .recipe_banner {
    flex-direction: row-reverse !important;
  }
  .receipe_block .item_row img,
  .receipe_block .item_row {
    height: 310px !important;
  }

  .export_form form .col-lg-6 {
    width: 50% !important;
  }
  .export_banner .right h5,
  .export_banner .right p {
    text-align: start !important;
  }
  .export_banner .right p {
    font-size: 14px !important;
  }
  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 4px !important;
    display: flex;
    flex-direction: column;
    width: 170px !important;
  }
  .brandsImages {
    margin: 0 !important;
  }

  .cat_expo_ban {
    flex-wrap: nowrap !important;
    flex-direction: row-reverse !important;
  }
  .smallscreen.exportscreenPlane {
    display: none !important;
  }
  .export_standars .standers {
    flex-wrap: nowrap !important;
  }
}

@media (max-width: 992px) and (min-width: 792px) {
  .products_types_info .product_continer {
    justify-content: center !important;
    gap: 20px !important;
    flex-wrap: wrap !important;
  }
  .brandsImages.brandCategory .swiper-slide {
  }
  .brandCategory .reciepe {
    display: flex;
    height: 290px !important;
    padding: 13px 13px 10px 13px !important;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    max-width: 100% !important;
    min-width: 100% !important;
    background-size: cover !important;
  }
  .brandsImages.brandCategory .swiper-slide {
    display: flex;
    justify-content: center;
    margin-bottom: 50px !important;
    align-items: flex-start !important;
    min-width: 24% !important;
    max-width: 24% !important;
  }
  .defaultLayout .brandsImages.brandCategory .swiper-slide p {
    font-size: 13px !important;
  }
}
.hero_section.d-flex.flex-column.justify-content-between.align-items-center.w-full.rowDiv.why_us_section.brands_section
  video {
  border-radius: 12px 12px 0 0;
}
@media (max-width: 870px) {
  .me_contactUs_cotainer {
    display: flex;
    flex-direction: column-reverse;
  }
  .me_send_button button,
  .CareersBtn {
    padding: 11px 80px !important;
  }
  .me_contactUs_cotainer > *,
  .left_content {
    max-width: 100% !important;
    min-width: 100% !important;
    width: 100% !important;
    padding-bottom: 19px;
  }
  .brandsImages.brandsImagesGrid .receipe_block .item_row img,
  .receipe_block .item_row,
  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.rowDiv.gridDiv
    > div.brandsImages.brandsImagesGrid
    > div
    > div.item_row
    > img {
    min-height: 250px !important;
    max-height: 250px !important;
  }

  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 4px !important;
    display: flex;
    flex-direction: column;
    width: 160px !important;
  }
  .brandsImages {
    margin: 0 !important;
  }

  .menuToggle a {
    font-size: 11px !important;
  }
  .receipe_details.brandShadow h4 {
    font-size: 13px !important;
  }
}

@media (max-width: 792px) {
  .recipe_banner > * {
    width: 100% !important;
  }
  .brandsImages.brandCategory .swiper-slide {
    min-width: 300px !important;
    max-width: 300px !important;
  }

  .products_types_info .product_continer > * {
    width: 100% !important;
  }

  .brandCategory .reciepe {
    height: 420px !important;
  }

  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.boxShadowSection
    > div.hero_section.d-flex.justify-content-between.align-items-center.w-full.rowDiv.why_us_section
    > div {
    min-width: 100% !important;
    padding: 0 20px !important;
  }
  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.boxShadowSection
    > div.hero_section.d-flex.justify-content-between.align-items-center.w-full.rowDiv.why_us_section
    img {
    min-width: 100% !important;
  }
  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.boxShadowSection
    > div.hero_section.d-flex.justify-content-between.align-items-center.w-full.rowDiv.why_us_section {
    padding: 0 !important;
  }
  .recipe_about .left,
  .recipe_about .right {
    width: 100% !important;
  }
  .recipe_banner {
    flex-direction: column !important;
  }
  .product_type_img img {
    width: min(510px, 100%) !important;
  }
  .receipe_block.brand_block {
    gap: 10px !important;
  }
  .receipe_block.brand_block {
    width: 150px !important;
  }
  .receipe_block.brand_block img {
    width: 100px !important;
  }
  .product_continer {
    flex-wrap: wrap !important;
  }
  .smallscreen.exportscreenPlane {
    display: initial !important;
  }
  .defaultLayout .export_form form .col-lg-6 {
    width: 100% !important;
  }
  /* .export_certificatios .certifications {
 
    grid-template-columns: repeat(auto-fill, minmax(230px, 4fr)) !important;
  }
  .export_certificatios .certifications > *, .export_certificatios .certifications img{
    width: 90px !important;
  } */

  .whowearestyles > * {
    width: 100%;
    font-size: 14px !important;
  }
  .defaultLayout .export_standars .standers {
    flex-wrap: wrap !important;
  }
  .cat_expo_ban > *,
  .cat_expo_ban > * > * {
    width: 100% !important;
    font-size: 14px !important;
  }
  .export_banner.rowDiv {
    flex-direction: column;

    flex-wrap: wrap !important;
  }
  .defaultLayout .export_banner.rowDiv > * {
    width: 100% !important;
  }
  .cat_expo_ban {
    flex-wrap: wrap !important;
  }
  .logo img {
    /* width: 60%; */
    /* margin-top: 24px; */
    object-fit: contain !important;
    width: 65px !important;
    height: 63px !important;
    height: auto !important;
    flex-shrink: 0;
    margin: 0;
  }

  .hero_texts p {
    font-size: 14px !important;
    font-weight: 400 !important;
  }

  .hone_sections_button {
    display: flex;
    width: fit-content !important;
    height: 31px !important;
    padding: 10px;
    justify-content: center;
    align-items: center;
    font-size: 14px !important;
    border-radius: 16px;
    background: var(--heading-color);
    color: var(--dark, var(--sec-color));
    font-family: Amaranth;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: 120%;
    border: 2px solid #ffd53e;
    transition: 0.4s ease-in-out;
    gap: 9px !important;
    padding: 10px !important;
  }
  .hone_sections_button {
    border-radius: 65px !important;
  }

  .footer .container .row > *,
  .single_footer {
    display: flex;
    flex-direction: column !important;
    min-width: 100% !important;
    align-items: flex-start !important;
  }

  .single_footer ul li {
    text-align: start !important;
  }
  .logo {
    object-fit: contain !important;
    width: 90px !important;
    height: 131px;
    flex-shrink: 0;
    margin: 0 !important;
  }
  .footer .container .row {
    flex-direction: column !important;
  }
  .hero_texts,
  .defaultLayout .hero_section > img {
    width: min(324px, 100%) !important;
  }
  .defaultLayout .hero_section > img {
    margin: auto !important;
  }
  .why_us_section p {
    font-size: 14px !important;
  }
  .hero_texts h1 {
    font-size: 23px !important;
  }
  .menu_Toggle .menuLabel.menuLabelleb:hover .menu {
    top: 0 !important;
  }
  .logo img {
    margin-top: 0 !important;
  }

  .hero_section h5 {
    font-size: 20px !important;
  }

  .boxShadowSection .hero_section > img {
    max-width: 320px !important;
  }
  .why_us_section p {
    font-size: 13px !important;
    margin-bottom: 0px !important;
  }
  .why_us_section button {
    margin-top: 12px;
  }
  .hero_texts {
    width: min(320px, 100%) !important;
  }
  .boxShadowSection .hero_section > img {
    width: min(250px, 100%) !important;
  }
  .menu_Toggle .menuToggle a {
    color: white !important;
  }
  .menu_Toggle .menuToggle a span {
    font-size: 18px !important
    ;
  }

  img.whowearestylesimg {
    height: 261px !important;
  }
  .Right_section .title {
    font-size: 23px !important;
  }

  .brandsImagesMainPages img {
    width: 240px !important;
    border-radius: 15px;
  }
  .recipe_details .features {
    flex-wrap: nowrap !important;
  }
  .features .feature_item h5,
  .features .feature_item p {
    font-size: 14px !important;
    text-align: center !important;
  }
  .recipe_details .features .feature_item {
    flex-direction: column !important;
    justify-content: center;
    align-items: center !important;
  }

  .recipe_about .left,
  .recipe_about .right {
    margin-top: 0px !important;
  }
  .feature_item .left img {
    width: 32px !important;
    height: 32px !important;
    margin: auto !important;
  }
  .imgContainer.col {
    margin-bottom: 102px !important;
  }
  .pord_steps_content {
    margin-bottom: 0 !important;
  }
  .mt-5.AreaRow > .row:first-child,
  .mt-5.AreaRow .row > .row {
    margin: auto !important;
  }
  .hero_section {
    margin-top: 0 !important;
    margin-bottom: 24px !important;

    width: 100% !important;
  }
  .Right_section .fill_up {
    font-size: 18px !important;
  }
  .list_toggle {
    display: inline-block !important;
  }

  .hero_texts {
    padding: 0 !important;
  }
  .receipe_details h4 {
    text-align: center !important;
  }

  .brandsImages {
    /* justify-content: space-between !important; */
    align-content: flex-start !important;
    justify-items: flex-start !important;
    width: 100% !important;
    margin: 0 auto !important;
  }

  .menu_Toggle .menuToggle a {
    font-size: 29px;
    margin-bottom: 20px !important;
    display: flex;
    text-align: center;
  }

  span.closeBtn {
    display: inline-block !important;
  }
  .list_toggle {
    display: block;
    cursor: pointer;
    font-size: 29px;
  }

  .enVersion .menu_Toggle {
    right: -150%;
  }

  /*  */

  .enVersion .menu_Toggle.active {
    right: 0;
  }
  .features .feature_item h5 {
    font-size: 14px !important;
    text-align: center !important;
    min-height: 29px !important;
  }

  .recipe_details .features {
    margin-top: 20px !important;
  }
  .recipe_banner .right img,
  .recipe_banner .right {
    border-radius: 8px !important;
  }
  .arVersion .menu_Toggle {
    right: auto !important;
    left: -150%;
  }
  .arVersion .menu_Toggle.active {
    left: 0 !important;
  }

  .arVersion .menu_Toggle .menuToggle {
    justify-content: flex-end !important;
    align-items: flex-end !important;
  }
  .arVersion .menuLabel.menuLabelleb {
    align-items: flex-end !important;
  }
  .arVersion .HeaderIcons .menuLabel:hover::before,
  .arVersion .HeaderIcons .menuLabel:hover .menu {
    left: 200% !important;
  }
  .arVersion .menu_Toggle .menuToggle a {
    flex-direction: row-reverse;
  }

  .arVersion .menuToggle a svg, .arVersion .single_footer h4 svg {
    rotate: 180deg !important;
  }

  .menu_Toggle .menuToggle {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .menu {
    top: 100% !important;
    z-index: 123762 !important;
  }

  .menu_Toggle .menuLabel .menu {
    /* width: 100%; */
    background: transparent !important;
    translate: 0 !important;
    left: 0 !important;
    position: relative !important;
    max-height: 0 !important;
    padding: 0 !important;
    overflow: hidden !important;
    /* color: white !important; */
  }
  .export_banner .right h5 {
    font-size: 23px !important;
  }
  .export_banner .right p {
    font-size: 18px !important;
  }

  .export_standars {
    box-shadow: none !important;
  }
  .menu_Toggle .menuToggle a:hover,
  .menu_Toggle .menuToggle .menu .open li a.active,
  .menuToggle .menuLabel .checked:checked ~ .menu a.active {
    color: var(--heading-color) !important;
  }
  ul.menu {
    top: 0 !important;
    translate: 0 !important;
  }
  .menuLabel .checked:checked ~ .menu {
    border-radius: 0px 0px 16px 16px;
    background: #fff;
    box-shadow: 0px 0px 0 0 transparent !important;
  }
  h3.whowearestylesh3,
  .downHeaderDiv.whoweare p.whowearestylesp,
  .downHeaderDiv.whoweare p.whowearestylesp p {
    text-align: start !important;
  }
  .menuLabel:hover .menu {
    border-radius: 0px 0px 16px 16px;
    background: #fff;
    box-shadow: 0px 4px 25px 0px transparent !important;
  }
  .menu {
    box-shadow: 0px 0px 0 0 transparent !important;
  }
  .menu_Toggle .menuLabel .checked:checked ~ .menu {
    max-height: 100vh !important;
    top: 0 !important;
  }
  .menu_Toggle .menuLabel .menu {
    top: 0 !important;
  }
  .rowDiv {
    flex-wrap: wrap !important;
  }
  .menu_Toggle {
    position: absolute !important;
    top: 0% !important;
    width: 320px !important;
    z-index: 234564321 !important;
    padding-top: 28px;
    min-height: 100vh !important;
    max-height: 100vh !important;
    overflow: auto;
    /* background: black !important; */
    transition: 0.5s ease-in-out !important;
    align-items: flex-start !important;
  }

  .imageHeader .headerImage img:first-child {
    top: 40px !important;
  }

  .menu_Toggle {
    background: var(--main-color) !important;
  }
  .hero_section h5 {
    font-size: 17px !important;
    font-weight: 400 !important;
  }

  body
    > div.defaultLayout
    > div.home_sections
    > div.boxShadowSection
    > div.hero_section.standard_section.d-flex.justify-content-between.flex-column.w-full.homeStandard.rowDiv
    > p {
    font-size: 18px !important;
    width: 100% !important;
    /* margin: 0 !important; */
    text-align: center !important;
  }

  .brandsImages.brandsImagesGrid .receipe_block .item_row img,
  .receipe_block .item_row,
  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.rowDiv.gridDiv
    > div.brandsImages.brandsImagesGrid
    > div
    > div.item_row
    > img {
    min-height: 400px !important;
    max-height: 400px !important;
  }

  .receipe_block,
  .receipe_block .item_row img,
  .receipe_block .item_row {
    gap: 24px !important;
    display: flex;
    flex-direction: column;
    width: 300px !important;
    margin: auto !important;
  }
  .brandsImagesMainPages img {
    width: 167px !important;
    height: 240px !important;
    object-fit: cover;
    border-radius: 15px;
    border-radius: 25px !important;
  }

  body
    > div.defaultLayout
    > div:nth-child(2)
    > div:nth-child(3)
    > div.brandsImages.brandsImagesMainPages {
    gap: 24px !important;
  }
  .why_us_section p {
    padding: 0 !important;
  }
  .standard {
    width: 166px !important;
    min-height: 240px !important;
    max-height: 240px !important;
    padding: 15px 15px 0 !important;
    padding: 0px 5px 0 !important;
    border-radius: 29px !important;
    gap: 40px !important;
    justify-content: center;
    align-items: center;
    position: relative;
    background: url("https://res.cloudinary.com/duovxefh6/image/upload/v1719224763/Rectangle_29_xzugug.png") !important;
    background-repeat: no-repeat !important;
    /* background: none !important */
  }
  .reciepe_name {
    border-radius: 0px 0px 18px 18px !important;
  }
  .brandsImages .swiper-slide img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    border-radius: 18px !important;
  }
  .menu_Toggle .menuToggle {
    gap: 32px !important;
  }
  body
    > div.defaultLayout
    > div:nth-child(2)
    > div.hero_section.map_section.d-flex.justify-content-between.w-full.rowDiv
    > div {
    padding: 0 9px !important;
  }
  .single_footer h4 {
    font-size: 23px !important;
  }
  .standard_section a {
    font-size: 18px !important;
  }
  .standard img.backgroundInternal {
    display: flex !important;
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }
  .standard p {
    padding: 0 !important;
    font-weight: 400 !important;
  }
  .standardsImages {
    gap: 24px !important;
  }

  .standard img {
    width: 38px !important;
    height: 38px !important;
    flex-shrink: 0;
    margin: 10px 0 0 0;
  }
}

@media (min-width: 1660px) {
  .hero_texts {
    width: 46% !important;
  }
  .hero_texts p {
    font-size: 2.7vh !important;
    width: 100% !important;
  }
  .hone_sections_button {
    font-size: 2.2vh !important;
    width: fit-content !important;
  }

  .hero_section h5,
  .enVersion .HeaderIcons .hoverable,
  .reciepe_name {
    font-size: 2.4vh !important;
  }

  .brandsImages > img {
    cursor: pointer;
    width: 33% !important;
  }
  .hero_texts h1,
  .hero_texts h1 span,
  .copyright {
    font-size: 3.7vh !important;
  }
  .hero_section > img {
    width: 45% !important;
  }

  button svg,
  span svg,
  .menuLabel svg,
  .productTypeSliderArrow button {
    width: 4vh;
    font-size: 4vh;
    height: 4vh;
  }

  .contact_info_row .icon svg,
  .contact_info_row svg,
  .me_social_icons .icon img,
  .me_social_icons .icon,
  .brands_section.why_us_section .rowDiv svg {
    width: 2.7vh;
    font-size: 2.7vh;
    height: 2.7vh;
  }

  .productTypeSliderArrow button.custom-next-button,
  .productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider,
  .productTypeSliderArrow button.custom-prev-button,
  .productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
    background: #fff100a1 !important;
    border-radius: 10vh !important;
    height: 6vh !important;
    width: 6vh !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }

  .brandsImages .swiper-slide {
    max-width: 60vh !important;
    width: 60vh !important;
    height: 69vh !important;
  }

  .reciepe,
  .brandsImages .swiper-slide {
    border-radius: 4vh !important;
  }

  .reciepe_name {
    padding: 3vh !important;
  }

  .single_footer.single_footer_address > img {
    width: 20vh !important;
    height: 20vh !important;
    object-fit: contain;
  }

  .menuLabel > label a,
  .text-center,
  a span,
  .single_footer ul li a,
  .single_footer h4 {
    font-size: 2.7vh !important;
  }
  .standard p,
  .single_footer .info,
  .export_standars .standers .stander p {
    font-size: 1.4vh !important;
  }
  .single_footer,
  .single_footer a,
  .single_footer span,
  .single_footer h2 {
    width: 23vh !important;
    font-size: 1.3vh !important;
  }
  .footer_element {
    min-width: 20vh !important;
    max-width: 20vh !important;
  }
  .footer .container {
    max-width: 80% !important;
    min-width: 80% !important;
  }
  .standard img,
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > div.mt-5.AreaRow
    > div
    > div
    > div
    > img,
  .value img,
  .export_standars .standers .stander img {
    width: 6.7vh;
    height: 6.7vh;
    object-fit: contain;
  }
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > div.mt-5.AreaRow
    > div
    > div
    > div
    > img {
    margin-bottom: 1vh;
  }
  header,
  .headerBackground,
  .header .headerBackground .rowDiv {
    min-height: 10.2vh !important;
  }
  .standard,
  .export_standars .standers .stander {
    width: 26vh !important;
    min-height: 30vh !important;

    align-items: center !important;
    justify-content: center !important;
  }

  .export_standars .standers .stander p {
    width: 82.4% !important;
  }
  .logo {
    object-fit: cover;
    width: 10vh !important;
    height: 5vh !important;
    flex-shrink: 0;
  }
  .headerImage {
    width: 50vh !important;
    height: 50vh !important;
  }
  .pord_steps_content > div .img,
  .pord_steps_content > div .rich_text {
    width: 45% !important;
  }

  .rich_text p,
  .pord_steps_content > div p strong,
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > div.mt-5.AreaRow
    > div
    > div
    p,
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > div.mt-5.AreaRow
    > div
    > div
    div
    h5,
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > center
    > p {
    margin-bottom: 1vh;
    font-size: 2vh !important;
  }
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    p,
  .certifications_page .texts p,
  .certifications_page.rowDiv p,
  .brands_section > p {
    font-size: 2vh !important;
    line-height: 2.4vh !important;
  }
  .brands_section.why_us_section .rowDiv p {
    font-size: 2vh !important;
  }
  .certifications_page .texts p {
    width: 100% !important;
  }
  .brands_section > p,
  .brands_section.why_us_section .rowDiv p {
    width: 86% !important;
  }

  .brands_section.why_us_section .rowDiv p {
    width: fit-content !important;
    margin: 0 1vh !important;
  }
  .brands_section.why_us_section .rowDiv > p {
    margin-bottom: 2vh !important;
  }
  .brands_section.why_us_section .rowDiv button {
    margin-top: 2vh !important;
  }
  .brands_section.why_us_section .rowDiv svg {
  }

  .certifications_page .texts {
    width: 86% !important;
  }
  .value {
    width: 38vh !important;
    height: 47vh !important;
  }

  .value img {
  }
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    h3,
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    h4,
  .certifications_page > h4 span,
  .breadcrumb span,
  .breadcrumb span button,
  .export_form form h5 {
    font-size: 3vh !important;
    margin-bottom: 2vh !important;
  }
  body
    > div.defaultLayout
    > div.px-4.my-5.text-start.downHeaderDiv.whoweare.rowDiv
    > div {
    margin-bottom: 2vh !important;
  }
  .menu_Toggle .menuLabel:hover .menu {
    top: 16.4vh !important;
    width: fit-content !important;
  }
  button,
  .boxShadowSection.BrandsShadowSection {
    border-radius: 0.8vh !important;
  }

  .menuLabel:hover .menu {
    border-radius: 0px 0px 1.8vh 1.8vh !important;
    background: #fff;
    /* box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15) !important; */
  }

  .brandsImages.brandCategory .swiper-slide,
  .brandsImages .swiper-slide .reciepe {
    width: 30vh !important;
    height: 50vh !important;
  }
  .brandsImages.brandCategory .swiper-wrapper {
    height: 60vh !important;
    gap: -1vh !important;
  }

  .brandsImages.brandCategory .swiper-slide,
  .brandsImages .swiper-slide .reciepe {
    width: 43vh !important;
    height: 55vh !important;
    max-width: 100vw !important;
    border-radius: 1vh !important;
  }
  .brandsImages.brandCategory .swiper-slide p,
  .brandsImages.brandCategory .swiper-slide a,
  .types_title p,
  .product_continer_text_data p,
  .export_form form p,
  button.hoverable.certificationsHoverable,
  .export_standars > h4 span,
  .export_certificatios > h4 span {
    font-size: 2.1vh !important;
  }
  .export_standars > p {
    font-size: 2.1vh !important;
    width: 86% !important;
  }
  .export_banner .right h5 {
    font-size: 3vh !important;
  }
  .export_banner .left,
  .export_banner .right {
    width: 45% !important;
  }
  .export_banner .texts,
  .export_banner .texts p {
    width: 95% !important;
  }
  .export_banner .texts,
  .export_banner .texts p {
    font-size: 2.2vh !important;
  }
  .viewAllBtn {
    width: fit-content !important;
    padding: 1.1vh;
  }
  button.hoverable.certificationsHoverable {
    width: fit-content !important;
    height: fit-content !important;
  }
  .menuLabel {
    margin: 0 1.3vh !important;
  }
  .product_continer_text_data_img img {
    width: 10vh !important;
  }

  .export_form form input::placeholder,
  .export_form form input,
  .export_form form textarea::placeholder,
  .export_form form textarea,
  .export_form form button,
  .export_form form .submit span,
  .me_contact_form input,
  .me_contact_form input::placeholder,
  .me_contact_form textarea::placeholder,
  .me_contact_form textarea,
  .me_contact_form select,
  .me_contact_form button,
  .careerContentstyle input,
  .careerContentstyle input::placeholder,
  .careerContentstyle textarea::placeholder,
  .careerContentstyle textarea,
  .careerContentstyle select,
  .careerContentstyle button,
  label[for="cover_file"],
  #cover_file {
    padding: 1.1vh 0.3vh !important;
    font-size: 2.2vh !important;
    padding: 1.2vh 0.3vh !important;
    border-radius: 1vh !important;
  }

  .breadcrumb span,
  .breadcrumb span button {
    margin-bottom: 0 !important;
  }
  .careerContentstyle .breadcrumb {
    margin-bottom: 0 !important;
  }
  textarea {
    min-height: 20vh !important;
  }
  .products_types_info,
  .downloadHeaderDiv,
  .careerContentForm {
    padding: 4vh !important;
  }
  .careerContentstyle form {
    padding: 4vh 0 !important;
  }

  .product_continer_img {
    width: 45% !important;
  }

  .ProductionSteps h4,
  .value h5,
  .certification_values h4,
  .certification_values h4 span,
  .menu li a {
    font-size: 3vh !important;
    margin: 2vh 0 !important;
  }
  .category_name,
  .careerContentstyle h2 {
    font-size: 3vh !important;
  }

  .me_contact_form .me_form_input .me_input_lable,
  .left_content .contact_info .contact_info_row .info,
  .careerContentstyle p {
    font-size: 2.1vh;
    line-height: 3.4vh;
  }
  .careerContentstyle > div {
    margin-bottom: 6vh !important;
  }
  .careerContentstyle .H2InLarge {
    margin-bottom: 2vh !important;
  }
  .left_content .contact_info {
    width: 60% !important;
  }

  .left_content {
    padding: 1vh 1.8vh 4vh !important;
  }

  .left_content .contact_map {
    width: 100%;
    height: 40vh !important;
    margin-bottom: 5vh !important;
  }
  .rowDiv.brandsImagesSlider .swiper-wrapper {
    align-items: center !important;

    height: 21vh !important;
  }

  .rowDiv.brandsImagesSlider .swiper {
    margin-bottom: 3vh;
    overflow: visible !important;
  }

  .categorySlider {
    padding: 4.5vh 0 !important;
  }

  .buttons {
    top: 56% !important;
  }
  .page_title,
  .product_continer_text_data h1,
  h2.rowDiv.categoryName {
    font-size: 3vh !important;
  }
  .sidebar_reciepe {
    min-width: 26vh !important;
  }
  .sidebar_reciepe span {
    font-size: 2.1vh !important;
  }
  .pord_steps_content > div .img img,
  .product_continer_img img {
    width: 100% !important;
  }
  .receipe_block img,
  .receipe_block > *,
  .receipe_block {
    min-width: 25vh !important;
    max-width: 25vh !important;
    width: 25vh !important;
    height: 40vh !important;
    border-radius: 1.2vh !important;
  }

  .receipe_details h4 {
    font-size: 2.6vh !important;
  }
  .breadcrumb {
    padding-bottom: 0 !important;
    margin-top: 6vh !important;
  }
  .sidebar_reciepe {
    gap: 4.8vh !important;
    border-radius: 0.8vh !important;
    padding: 2.3vh 3vh 7.4vh 3.9vh !important;
  }
  .category img {
    border-radius: 100vh !important;
  }
  .logo img {
    width: 100%;
    margin-top: 2vh !important;
    object-fit: cover;
  }
  .headerImage img {
    height: 20vh !important;
  }
  .export_banner .right h5 {
    font-size: 6vh !important;
  }
  .export_banner .right p {
    font-size: 3vh !important;
  }

  .export_contries .contries .content div span:nth-of-type(1) {
    font-size: 4vh !important;
  }
  .categorySlider .swiper-slide {
    width: 20vh !important;
  }
  .category {
    width: 20vh !important;
    height: 20vh !important;
    border-radius: 20vh !important;
  }
  .me_cotactUs_page.rowDiv,
  .me_cotactUs_page.rowDiv .me_contactUs_cotainer {
    min-height: 120vh !important;
  }

  .me_contactUs_cotainer .left_section .image,
  .me_contactUs_cotainer .left_section,
  .Right_section {
    height: 120vh !important;
  }
  .me_contactUs_cotainer .left_section,
  .Right_section {
    width: 50% !important;
  }
  .Right_section .title {
    font-size: 4vh;
  }
  .Right_section .fill_up {
    font-size: 2.4vh !important;
  }
  .receipe_details {
    height: fit-content !important;
  }
  .brandsImages.brandsImagesGrid.brands_block .receipe_block {
    height: 60vh !important;
    min-width: 35vh !important;
    max-width: 35vh !important;
    width: 35vh !important;
    /* heigh !important; */
  }
  .brandFilter {
    font-size: 2.8vh !important;
  }
  .downloadHeaderDiv.products_types_page
    .ProductTypeSmallSlider
    .brandsImages
    .swiper-slide {
    width: 30vh !important;
  }
  .product_type_name {
    font-size: 2.1vh !important;
    line-height: 2vh !important;
    width: 30vh !important;
  }
  .home_sections .brandsImages .swiper-slide .reciepe {
    width: 100% !important;
    height: 100% !important;
  }
  .recipe_banner {
    height: 77vh !important;
  }
  .features .feature_item h5,
  .features .feature_item p {
    font-size: 2.3vh !important;
  }
  .recipe_about .left {
    width: 50vh !important;
  }

  .recipe_about .right {
    margin: 0px !important;
    margin-top: 40px !important;
    width: calc(100% - 70vh) !important;
  }

  .right p,
  .right h3,
  .bottom p,
  .bottom h3,
  .bottom li,
  .right li {
    font-size: 2.5vh !important;
    margin-bottom: 1vh !important;
    line-height: 2.6vh !important;
  }
  .recipe_banner .left,
  .recipe_banner .right {
    height: 100% !important;
  }

  .downloadHeaderDiv .downHeaderDiv {
    padding-top: 5.5vh !important;
  }
  .brandsImages.brandsImagesGrid.brands_block .receipe_block {
    justify-content: space-between !important;
  }
}

.brandsImages.brandCategory .swiper {
  margin: 0 !important;
  width: 100% !important;
}

/* New Mobile Version */
@media (max-width: 792px) {
  .menu li a {
    font-size: 18px !important;
  }
  body > div.defaultLayout > div > div.row.text-center.buildingsContainer {
    flex-direction: column !important;
  }
  body > div.defaultLayout > div > div.row.text-center.buildingsContainer > * {
    width: 100% !important;
  }
  .downHeaderDiv.whoweare p {
    font-size: 18px !important;
  }
  .value p {
  }
  .value {
    background: url("https://res.cloudinary.com/duovxefh6/image/upload/v1719835261/Frame_3708--removebg-preview_osnh4p.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 95% !important;
    height: 350px !important;
    background-position: center;
  }

  /* .value img {
    width: 80px !important;
    height: 80px !important;
  } */

  .value p {
    padding: 0 10px !important;
  }
  .products_types_info .product_continer {
    flex-direction: column !important;
  }

  .certification_values {
    margin-bottom: 24px !important;
    padding-bottom: 24px !important;
  }

  .certification_values .values {
    margin: 24px 0 !important;
    gap: 24px !important;
  }

  body
    > div.defaultLayout
    > div.export_page
    > div:nth-child(3)
    > div.export_certificatios.export_certificatios_page
    > div.swiper.swiper-initialized.swiper-horizontal.cert_swiper.swiper-backface-hidden
    > div {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px !important;
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .products_types_info .product_continer {
    flex-direction: column !important;
  }
  .certifications.hideFromMobile {
    display: none !important;
  }
  .value img {
    width: 60px !important;
    height: 60px !important;
  }
  .value p {
    width: 100% !important;
    font-size: 14px !important;
  }
  .certifications_page.rowDiv p {
    text-align: center !important;
    width: 98% !important;
  }
  .left_content .contact_info .contact_info_row .info {
    max-width: 240px !important;
    font-size: 13px !important;
  }
  .home_sections .productTypeSliderArrow {
    display: none !important;
  }
  .hero_texts a {
    flex-wrap: nowrap !important;
  }
  .single_footer.single_footer_address > img {
    width: 116px !important;
    height: 108px !important;
  }
  .menu_Toggle .menu li a {
    padding: 0 !important;
    margin: 0 !important;
    opacity: 1 !important;
    color: white !important;
  }
  .menu_Toggle .menu li,
  .menuToggle .menuLabel .checked:checked ~ .menu li {
    padding: 20px 0 !important;
    margin: 0 !important;
    opacity: 1 !important;
    color: white !important;
  }
  .menu_Toggle .menuToggle a {
  }
  .HeaderIcons .menuLabel:hover::before,
  .HeaderIcons .menuLabel:hover .menu,
  .HeaderIcons .menuLabel::before,
  .HeaderIcons .menuLabel .menu {
    top: 86px !important;
    left: 0%;
    translate: -60% 0 !important;
  }
  .HeaderIcons .menuLabel:hover::before,
  .HeaderIcons .menuLabel:hover .menu {
    top: 86px !important;
    left: 0%;
    translate: -60% 0 !important;
  }
  .reciepe,
  .brandsImages .swiper-slide {
    width: 166px !important;
    height: 300px !important;
  }
  .brandsImages .swiper-slide {
    margin-right: 24px !important;
  }
}

video::-webkit-media-controls {
  display: none !important;
  opacity: 0;
}
video::-webkit-media-controls-start-playback-button {
  display: none !important;
}

body div p.text-center {
  text-align: center !important;
}

.hero_section.d-flex.flex-column.justify-content-between.align-items-center.w-full.rowDiv.why_us_section.brands_section
  p {
  text-align: center !important;
}

a,
.arVersion .hero_texts a span:first-child {
  text-decoration: none !important;
  font-family: "Tajawal", sans-serif !important;
}

.imgContainer h5,
.imgContainer p {
  height: 30px;
}

.imgContainer p {
  height: 60px;
}

.arVersion .products_types_info .product_continer:nth-child(2n + 1) {
  flex-wrap: nowrap !important;
  gap: 10px;
  justify-content: center !important;
  flex-direction: row-reverse;
}

.arVersion .product_continer:nth-child(2n) .product_continer_text_data_img {
  flex-direction: row-reverse !important;
}
