.searchBox {
  border-radius: 16px;
  border: 1px solid var(--main-color);
  background: var(--white, #fff);
  /* width: min(821px, 95%); */
  height: 60px;

  flex-shrink: 0;
  display: flex;
  align-items: center;
  z-index: 12123213123 !important;
  position: absolute;
  top: 100px;
  justify-content: space-between;
  width: 87% !important;
  /* width: 50% !important; */
  margin: auto !important;
  left: 50% !important;
  transform: translateX(-50%);
  /* background: red; */
  /* height: 30px !important; */
  position: absolute;
  /* left: 0px; */
  /* left: 50%; */
  flex-shrink: 0;
  margin-top: 80px !important;
}

.overLay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
}

input[type="search"] {
  width: 90%;
  /* height: 100%; */
  border-radius: 8px;
  border: none;
  outline: none;
  font-size: 18px;
  padding: 18px;
  background: transparent;
}

input::placeholder {
  color: var(--secondery-color, #3d4c5e);
  text-align: right;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 120%;
}

input[type="search"] {
  outline: none;
}

.btn-search {
  display: flex;
  width: 127px !important;
  height: 58px;
  padding: 17px 23px 17px 24px;
  border-radius: 8px;
  background: var(--main-color);
  color: white;
  outline: none;
  justify-content: center;
  align-items: center;
  gap: 10px;
  flex-shrink: 0;
  border: none;
  cursor: pointer;
}

/* في ملف style.css */

.searchResult {
  display: flex;
  flex-wrap: wrap;
  /* justify-content: center; */
  align-items: center;
  position: absolute;
  width: 100%;
  background: white;
  border-radius: 0px 10px 0px 0px;
  z-index: 12213143324234 !important;
  top: 100%;
  min-height: 300px;
  max-height: 65vh;
  overflow: auto;
  padding: 20px;
}

.searchResult .product {
  margin: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 200px;
  cursor: pointer;
  height: 300px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
}

.searchResult .product img {
  max-width: 100%;
  height: auto;
  cursor: pointer;
}

.searchResult .product p {
  margin-top: 10px;
  font-size: 14px;
  text-align: center;
}

/* .over_lay {
  position: absolute;
  width: 100%;
  left: 0px;
  top: 0px;
  background-color: rgba(0, 0, 0, 0.292);
  height: 100%;
  z-index: 123343434343 !important;
} */

.over_lay {
  position: fixed;
  background: #00000063;
  width: 100% !important;
  height: 100%;
  top: 0;
  left: 0 !important;
  display: flex;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  padding: 100px 0 !important;
  z-index: 123423567 !important;
}

.HeaderIcons {
  z-index: 1231 !important;
}

body
  > div.defaultLayout
  > div.recipe_details
  > div.recipe_about.rowDiv
  > div.right
  > p
  > ul
  > li
  > span,
body
  > div.defaultLayout
  > div.recipe_details
  > div.recipe_about.rowDiv
  > div.right
  > p
  > ul
  a {
  color: rgb(0 47 89) !important;
}

.recipe_about.rowDiv {
  align-items: flex-start !important;
}
