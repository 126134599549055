.export_certificatios {
  margin-top: 100px;
  margin-bottom: 60px;
}

.cert_swiper {
  display: none;
}

.export_certificatios>h4 {
  text-align: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  color: var(--primary-orouba, #035297);
  font-family: Amaranth;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  margin-bottom: 8px;
}

.export_certificatios>h4 span:first-of-type {
  color: var(--dark, #002f59);
  font-family: Amaranth;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

/* .export_certificatios .certifications {
  display: grid;
  row-gap: 60px;
  text-align: center;
  margin-top: 50px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 4fr));
} */

.export_certificatios .certifications .certification {
  width: 200px;
  margin: auto;
}

.export_certificatios .certifications img {
  width: 100%;
}

@media (max-width: 992px) {
  .export_certificatios .certifications.not_make_hide {
    /* display: block; */
    margin-top: -50px !important;
  }
}

@media (max-width: 767px) {
  .export_certificatios .certifications img {
    margin: auto !important;
  }

  /* .export_certificatios .certifications {
    grid-template-columns: repeat(auto-fill, minmax(250px, 4fr));
  } */

  .export_certificatios>h4 {
    font-family: Amaranth;
    font-size: 23px;
    font-weight: 700;
    line-height: 27.6px;
    text-align: left;
  }

  .export_certificatios>h4 span:first-of-type {
    font-family: Amaranth;
    font-size: 23px;
    font-weight: 700;
    line-height: 27.6px;
    text-align: left;
  }

  .cert_swiper {
    margin-top: 50px;
    display: block;
  }

  .cert_swiper.make_hide {
    display: none;
  }

  /* .export_certificatios .certifications {
    display: none;
  } */

  @media (max-width: 930px) {
    .export_certificatios {
      margin-bottom: 30px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
    }

    .export_certificatios .certifications.not_make_hide {
      display: flex !important;
      width: 326px !important;
      /* flex-direction: column; */
      justify-content: center !important;
      align-items: center !important;
      flex-wrap: wrap;
      gap: 24px;
      margin: 24px auto !important;
    }

    .certification_values {
      padding-top: 10px;
      padding: 40px 30px !important;
      background-size: cover !important;
      background-repeat: no-repeat;
      margin-bottom: 140px;
      border-radius: 16px !important;
      background-position: center !important;
    }

    .certification_values {
      padding: 10px;
      display: flex;
      padding: 35px 10px 98px 10px !important;
      flex-direction: column;
      align-items: center;
    }

    .value p {
      font-size: 13px !important;
      padding: 10px !important;
    }

    .brandOncategory .swiper,
    .brandOncategory .swiper-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100% !important;
    }

    .export_certificatios .certifications img,
    .export_certificatios .certifications .certification {
      width: 78px !important;
    }

    .product_continer_text_data_img {
      gap: 40px !important;
    }

    .product_continer_text_data {
      width: 100% !important;
    }
  }

  .export_certificatios .certifications .certification {
    max-width: 100%;
  }

  .min_mar {
    margin-top: 20px !important;
  }
}

@media (max-width: 565px) {
  /* .export_certificatios .certifications {
    grid-template-columns: repeat(auto-fill, minmax(200px, 4fr));
  }

  .export_certificatios .certifications .certification {
    width: 180px;
  } */

  .export_certificatios .certificationsHoverable {
    margin-top: 20px !important;
    border-radius: 8px !important;
    width: 146px !important;
    gap: 2px;
    font-family: Amaranth;
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    text-align: left;
  }

  .export_certificatios .certificationsHoverable span {
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 767px) {
  .export_certificatios {
    margin-bottom: 30px;
  }
}

/*
button.hoverable.certificationsHoverable svg path {
  color: white !important;
}

button.hoverable.certificationsHoverable svg path {
  fill: white !important;
} */

.downloadHeaderDiv.products_types_page .ProductTypeSmallSlider .brandsImages .swiper-slide {
  max-height: 370px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageHeader {
  position: absolute;
  width: 100%;
  z-index: 1;
  height: 100%;
}

.downloadHeaderDiv.products_types_page .brandsImages .swiper-slide .product_type img {
  width: 263.229px !important;
  height: 222px !important;
  max-height: 222px !important;
  object-fit: contain !important;
}


.export_certificatios .certifications {
  display: flex;
  gap: 30px !important;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-top: 25px !important;
}

.export_certificatios .certifications>* {
  width: 20% !important;
}

.export_certificatios .certifications>* img {
  width: 100%;
  object-fit: contain;
}