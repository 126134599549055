.standard {
  width: 283px;
  min-height: 295px;
  flex-shrink: 0;
  background: url("../../assets/standards_bg.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 25px;
}

.standard img {
  width: 76px;
  height: 76px;
  flex-shrink: 0;
  margin: 10px 0 0 0;
}

.standard p {
  color: var(--white, #fff);
  text-align: center !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  padding: 0 19px;
}

.standard_section a {
  color: var(--dark, #002f59);
  text-align: center !important;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-decoration: none;
  margin-bottom: 19px;
}

.standard_texts p {
  text-align: center !important;
}
