.category {
  width: 150px;
  height: 150px;
  flex-shrink: 0;
  border-radius: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.category img {
  padding: 9px;
  border-radius: 150px;
  width: 100%;
}
.categorySlider.arabicCS {
  direction: rtl;
  justify-content: flex-start;
}

body.arVersion .categorySlider.arabicCS .swiper-wrapper {
  justify-content: flex-start;
  direction: rtl !important;
}

.categorySlider {
  padding: 20px 0;
}

.category-custom-prev-button,
.category-custom-next-button {
  position: absolute;
  width: 10px;
  height: 150px;
  /* background-color: white; */
  display: flex;

  align-items: center;
  left: 0;
  cursor: pointer;
  justify-content: flex-end;
}

.arVersion .category-custom-prev-button {
  left: -4% !important;
}

.arVersion .category-custom-next-button {
  right: 0 !important;
}

.category-custom-prev-button svg,
.category-custom-next-button svg {
  width: 3em;
  height: 3em;
}

.category-custom-next-button {
  left: auto;
  right: -40px !important;
}

.categorySlider .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 10px;
}

.buttons {
  position: absolute;
  width: 100%;
  top: 40%;
  /* left: 0; */
  translate: 0;
  max-width: 120%;
  display: flex;
  z-index: 3;
  justify-content: space-between;
}

.category img {
  padding: 3px;
  border-radius: 150px;
  width: 100%;
  border: 3px solid var(--heading-color);
}

.brandCategory .reciepe img {
  border-radius: 8px;
  height: 426px;
  width: min(400px, 100%);
}
.reciepe_name {
  max-width: 100% !important;
  width: 100% !important;
}
.brandCategory .reciepe_name {
  position: relative;
  color: black !important;
  background-color: none;
}

.categorySlider {
  gap: 15px !important;
}

.category {
  /* margin: 0 20px !important; */
}

.categorySlider .swiper-slide {
  width: 160px !important;
  /* margin: 0 20px !important; */
}

.arVersion .product_continer_text_data p,
.arVersion .product_continer_text_data p span {
  font-weight: lighter !important;
}

.arVersion .recipe_banner .left .content h5 {
  font-size: 31px !important;
}

.breadcrumb span button {
  font-size: 16px !important;
}

.arVersion .recipe_about.rowDiv p,
.arVersion .recipe_about.rowDiv li,
.arVersion .recipe_about.rowDiv a,
.arVersion .recipe_about.rowDiv span,
.arVersion .recipe_about.rowDiv strong,
.arVersion .features .feature_item p {
  font-size: 20px !important;
}

.arVersion .right h3 {
  font-size: 31px !important;
}

.arVersion .bottom ol,
.arVersion .bottom ol li,
.arVersion .features .feature_item .bottom p,
.arVersion .recipe_about.rowDiv .bottom a,
.arVersion .recipe_about.rowDiv .bottom li,
.arVersion .recipe_about.rowDiv .bottom p,
.arVersion .recipe_about.rowDiv .bottom span,
.arVersion .recipe_about.rowDiv .bottom strong {
  list-style: arabic-indic !important;
}

