.page_links {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 90%;
  margin: auto;
}

.page_links a {
  text-decoration: none;
  padding: 2px;
  font-size: large;
  color: #02447e;
  font-weight: bolder;
}

.page_links p {
  font-size: large;
  color: #236bab;
  font-weight: bolder;
}

.products_types_info,
.downloadHeaderDiv {
  width: 100%;
  /* height: 100vh; */
  margin: auto;
  padding: 15px !important;
  /* display: flex; */
  background-image: url("https://res.cloudinary.com/duovxefh6/image/upload/v1716720622/xxc_vmu8ac.jpg");
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgb(176, 176, 176);
  margin-bottom: 20px;
}

.downloadHeaderDiv .product_continer_text_data {
  width: 100% !important;
  text-align: start !important;
}

.arVersion .downloadHeaderDiv .product_continer_text_data {
  text-align: start !important;
  /* padding-right: 86px; */
}

/* .enVersion .downloadHeaderDiv .product_continer_text_data {
  padding-left: 86px;
} */
.downloadHeaderDiv .product_continer {
  flex-direction: row !important;
}

.arVersion .products_types_info {
  direction: rtl !important;
  text-align: right !important;
}

.downloadHeaderDiv {
  width: 100%;
  min-height: 100vh;
  margin-bottom: 0 !important;
}

.downloadHeaderDiv .products_types_info {
  background-image: none !important;
}

img.background_img {
  width: 100%;
  position: absolute;
  top: 0;
  height: 1;
  z-index: 4;
  left: 0;
}

.downloadHeaderDiv .swiper {
  width: 100%;
}

.downloadHeaderDiv .products_types_info,
.downloadHeaderDiv {
  border-radius: 0 !important;
  margin-bottom: 0 !important;
  padding: 15px 0 !important;
}

.downloadHeaderDiv .downHeaderDiv {
  margin-top: -99px !important;
  padding-top: 145px;
  position: relative !important;
}

body>div.defaultLayout>div.downloadHeaderDiv.products_types_page>div.hero_section.pb-4.ProductTypeSmallSlider.reciepe_section.d-flex.justify-content-between.flex-column.w-full.rowDiv {
  margin-top: 10px !important;
}

.page_title {
  padding: 20px 50px;
  font-size: 40px;
  font-weight: bolder;
  color: #035297;
  margin: 0px;
}

/* .product_continer:nth-child(2n + 1) {
  flex-direction: row-reverse;
} */
ul.menu {
  top: 104px !important;
}

.product_continer {
  width: 100%;
  /* height: 20vh; */
  /* background-color: red; */
  display: flex;
  /* flex-wrap: wrap; */
}

.product_continer_img {
  width: min(550px, 100%);
  /* height: 20vh; */
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: blue; */
}

.product_continer_img img {
  width: min(550px, 100%);
}

header {
  z-index: 1235542 !important;
}

.product_type_img img {
  width: min(510px, 100%);
  max-height: 400px;
  object-fit: contain;
}

.product_type_img {
  justify-content: flex-start !important;
}

.product_continer_text {
  width: min(550px, 100%);
  /* background-color: blueviolet; */
  display: flex;
  justify-content: center;
  align-items: center;
}

.product_continer_text_data {
  width: 70%;
  height: fit-content;
  text-align: center;
}

.product_continer_text_data h1 {
  font-size: 40px;
  font-weight: bolder !important;
  color: #02447e !important;
}

.downloadHeaderDiv {
  background: #ffa0a4;
}

.product_continer_text_data p, .product_continer_text_data p span {
  font-size: 18px !important;
  font-weight: bolder !important;
  color: #02447e !important;
}

.product_continer_text_data_img {
  display: flex;
  width: fit-content;
  margin: auto;
}

.product_continer_text_data_img img {
  width: 90px;
  margin: 5px;
  transition: .5s ease-in-out;
  cursor: pointer;
}

.product_continer_text_data_img img:hover {
  scale: 1.2;
}


.product_type {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 12px;
  color: var(--dark, #002F59);
  font-family: Amaranth;
  font-size: 28px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
}

.arVersion .product_continer_text_data p {
  text-align: right !important;
}