.value {
  /* height: 395px; */
  margin: auto;
  /* width: 100%; */
  width: 370px;
  height: 460px;
  background-image: url("../../../../assets/Rectangle\ 29.png");
  background-repeat: no-repeat;
  background-position: top;
  background-size: cover;
  display: flex;
  padding: 75px 17px 80px 17px;
  flex-direction: column;
  justify-content: center;
  /* padding: 58px 17px 81px 17px; */
  align-items: center;
}
.value h5 {
  color: var(--white, #FFF);

  /* 48px */

  /* tilte-bold */
  font-family: Amaranth;
  font-size: 23px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  /* 27.6px */
}

.value p {
  width: 90%;
  margin: 4px auto;
  color: var(--white, #FFF);
  text-align: center;

  /* body */
  font-family: Amaranth;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  /* 27px */
}

@media (max-width:565px) {
  .value {
    width: 100%;
    height: 500px;
    text-align: center;
    justify-content: center;
    padding: 62px 22px;
  }
}

@media (max-width:500px) {
  .value {
    /* padding: 10px; */
    height: 400px;
  }

  .value p {
    margin-right: 0px;
    margin-left: -3px;
    width: 80%;
  }
}
