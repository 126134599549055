.reciepe_section .hero_texts {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100%;
  display: flex !important;
  justify-content: space-between !important;
  flex-direction: row !important;
  align-items: center;
}

.reciepe_name {
  border-radius: 0px 0px 8px 8px;
  /* opacity: 0.6; */
  left: 0;
  background: var(--dark, #002f59a0);
  position: absolute;
  z-index: 3423 !important;
  bottom: 0;
  width: min(400px, 100%);
  padding: 25px 14px;
  color: white;
  font-size: 28px;
  max-width: 100%;
}
.hero_texts {
  padding: 20px 0 !important;
}

.reciepe img {
  width: 100%;
  border-radius: 8px;
}

.reciepe,
.brandsImages .swiper-slide {
  border-radius: 8px;
}

.recipe_banner .right img {
  height: 100%;
  object-fit: cover;
  width: 100%;
}

/* .brandsImages .swiper-slide {
  min-width: 302px !important;
} */
/* .reciepe {
  width: min(400px, 100%);
} */

.productTypeSliderArrow {
  position: absolute;
  top: 29%;
  z-index: 12233 !important;
  /* transform: ; */
  width: 100%;
}

.productTypeSliderArrow button.custom-prev-button,
.productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider  {
  right: -8% !important;
  position: absolute;
}

.productTypeSliderArrow button.custom-prev-button,
.productTypeSliderArrow button.custom-next-button,
.productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider,
.productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
  width: 62px;
  height: 62px;
  background: #fff100a1;
  border-radius: 62px;
}
.productTypeSliderArrow button.custom-next-button, .productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider {
  left: -7.8% !important;
  position: absolute;
}

.enVersion .productTypeSliderArrow button.custom-prev-button,
.enVersion .productTypeSliderArrow button.custom-prev-button-ProductTypeSmallSlider {
  right: -5.5% !important;
}

.enVersion .productTypeSliderArrow button.custom-next-button, 
.productTypeSliderArrow button.custom-next-button-ProductTypeSmallSlider  {
  left: -8% !important;
}

.brandsImages .product_type img {
  width: 285px !important;
  height: 241px;
  border-radius: 8px;
  object-fit: contain;
}

.hero_texts a {
  color: var(--dark, #002f59);
  text-align: center;
  font-family: "Amaranth", sans-serif !important;
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  text-decoration-line: underline !important;
}

.hero_texts a span:first-child {
  text-decoration: underline !important;
  font-family: "Amaranth", sans-serif !important;
}


.brandsImages.rec_brand_imgs.active .swiper-wrapper {
  justify-content: end;
}