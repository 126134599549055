/* style.css */

.menuLabel {
  position: relative;
  margin-right: 20px; /* Adjust spacing between menu labels */
}

.menuToggle a svg {
  display: none !important;
}

.HeaderIcons .menuToggle a svg {
  display: flex !important;
}

.HeaderIcons .menuLabel:hover .menu a {
  font-size: 19px !important;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  /* display: none; */
  pointer-events: none;
  opacity: 0;
  transition: 0.4s ease-in-out;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #fff;
  box-shadow: 1px 2px 24px -2px #80808069;
  border-radius: 10px;
  /* border: 1px solid #ccc; */
}

.menuLabel::before {
  content: "";
  width: 100%;
  min-height: 131px;
  display: none;
  position: absolute;
  top: 100%;
  background: transparent;
}

.headerBackground .menuToggle {
  margin-top: 24px;
}

.menu_Toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 123;
}

.menuLabel:hover .menu,
.menu.open {
  display: block;
}

.menu li {
  display: block;
  text-align: center;
  padding: 4px 20px;
}

.menu li a {
  display: flex;
  padding: 10px 30px;
  text-decoration: none;
  color: black;
  width: 100%;
  white-space: nowrap;
}

.menuLabel:hover::before,
.menuLabel:hover .menu {
  pointer-events: initial !important;

  opacity: 1;
  display: block; /* Show menu when hovered over */
}

.menuLabel:hover .menu {
  border-radius: 0px 0px 16px 16px;
  background: #fff;
  box-shadow: 0px 4px 25px 0px rgba(0, 0, 0, 0.15) !important;
}
span.closeBtn {
  display: none;
}
span.closeBtn {
  color: black;
  margin-right: auto;
  margin-bottom: 30px;
  font-size: 30px;
}

.menu_Toggle .menuLabel:hover .menu {
  top: 124px !important;
}
.menuToggle .menuLabel a {
  text-decoration: none !important;
}
/* Media query for smaller screens */
@media (max-width: 768px) {
  .menuLabel {
    cursor: pointer;
  }
  .menuLabel {
    position: relative;
    margin-right: 10px !important;
    margin-right: auto !important;
    display: flex;
    flex-direction: column;
    justify-content: flex-start !important;
    align-items: flex-start;
  }
  .menuToggle .menuLabel .checked {
    display: none;
  }
  .menuToggle .menuLabel .checked:checked ~ .menu a {
    padding: 0 !important;
  }
  .menuToggle a svg {
    display: flex !important;
  }
  .menu_Toggle .menuLabel.menuLabelleb .menu {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  .menu_Toggle .menuToggle a,
  .menuLabel li a {
    text-align: start !important;
  }
  .menuLabel:hover::before,
  .menuLabel .checked:checked ~ .menu {
    pointer-events: initial !important;
    opacity: 1;
    display: block; /* Show menu when hovered over */
  }

  .menu.open {
    display: block; /* Show menu when clicked */
  }
}

.menuToggle {
  display: flex;
  align-items: center;
  color: white;
}

.menuLabel > label a {
  color: var(--white, #fff);
  font-family: Amaranth;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-decoration: none;
}

.menuToggle a.active {
  color: var(--heading-color) !important;
}
.menuToggle a {
  transition: 0.5s ease-in-out;
}

.menuToggle a:hover {
  color: var(--heading-color) !important;
}

.menuToggle .menuLabel:hover .menu a.active {
  color: #035297 !important;
}
