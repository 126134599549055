.brandOncategory .breadcrumb {
    margin: auto;
}


.boxShadowSection.BrandsShadowSection {
    background: #7CB021;
}


.brandsImages.brandCategory .swiper {
    margin: auto !important;
    margin-bottom: 20px !important;
}